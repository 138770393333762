import {Component, HostListener} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-ai-search',
  templateUrl: './ai-search.component.html',
  styleUrls: ['./ai-search.component.scss']
})
export class AiSearchComponent {
  public fa = icons;
  public cursor;
  public articles = [];
  public isLoadingNew = false;
  public companies = {};
  constructor(private dataService: DataService) {
    this.loadArticles();
  }

  loadArticles() {
    if (!this.isLoadingNew) {
      this.isLoadingNew = true;
      this.dataService.getLatestArticles(10, this.cursor).subscribe(response => {
        this.articles.push(...response.articles);
        Array.from(new Set(response.articles.map(obj => obj.company))).forEach(company => {
          // @ts-ignore
          if (!this.companies[company]) {
            this.dataService.getCompany(company).subscribe(cRe => {
              this.companies[cRe.id] = cRe;
            });
          }
        });
        this.cursor = response.cursor;
        this.isLoadingNew = false;
      });
    }
  }

  getCompanyName(id) {
    if (this.companies && this.companies[id]) {
      return this.companies[id].name;
    }
    return '-';
  }

  articleLink(id, title) {
    return id + '-' + title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  }
}
