import {Component, OnInit} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {AlertService} from '../../services/alert.service';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-new-organisation',
  templateUrl: './new-organisation.component.html',
  styleUrls: ['./new-organisation.component.scss']
})
export class NewOrganisationComponent implements OnInit {
  public fa = icons;

  public saving = false;
  public company = {
    name: '', identifier: '', description: '', image: '', agree: false,
    sub_info: {
      subscription: '', billing: '', billing_email: '', billing_phonenumber: '', billing_vatnumber: ''
    }
  };


  constructor(private alertService: AlertService, private dataService: DataService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.changeReady.subscribe(message => {
      if (message) {
        this.company.sub_info.billing_email = message.email;
      }
    });
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (!file || !file.type.startsWith('image/')) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.company.image = reader.result.toString();
    };
  }

  onSubscriptionSelect(plan) {
    if (['basic', 'premium', 'unlimited'].includes(plan)) {
      return;
    }

    this.company.sub_info.billing = '';
    this.company.sub_info.subscription = plan;
  }

  canSave() {
    if (this.company.name !== '' && this.company.identifier !== '' && this.company.agree &&
      this.company.description !== '' && this.company.image !== '' && this.company.sub_info.subscription === 'free') {
      return true;
    }

    return this.company.name !== '' && this.company.identifier !== '' &&
      this.company.description !== '' && this.company.image !== '' &&
      this.company.sub_info.subscription !== '' && this.company.sub_info.billing !== '' &&
      this.company.agree && this.company.sub_info.billing_email !== '' &&
      this.company.sub_info.billing_phonenumber !== '' &&
      (this.company.sub_info.billing === 'automatic_monthly' || this.company.sub_info.billing_vatnumber !== '');
  }

  updateIdentifier() {
    this.company.identifier = this.company.name.toLowerCase().replace(/\s+/g, '');
  }


  saveChanges() {
    if (this.canSave()) {
      this.saving = true;

      this.dataService.postCompany(this.company).subscribe(response => {
        this.alertService.alert(
          {
            content: `The company was created and your permissions were changed. You will be redirected to the login screen.`,
            title: 'Success',
            type: 'info'
          });
        this.saving = false;
        setTimeout(() => {
          window.location.href = '/auth?to=/org/' + response;
        }, 2000);
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}`,
            title: 'Something went wrong',
            type: 'error'
          });
        this.saving = false;
      });
    } else {
      this.callBlockingSave();
    }
  }

  callBlockingSave() {
    const element = document.getElementById('saver');
    const element2 = document.getElementById('saver2');
    element.classList.add('blocking');
    element2.classList.add('blocking');
    setTimeout(() => {
      element.classList.remove('blocking');
      element2.classList.remove('blocking');
    }, 1000);
  }

}
