import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../../../services/alert.service';
import {DataService} from '../../../../services/data.service';
import {ActivatedRoute} from '@angular/router';
import {EnvService} from '../../../../services/env.service';

@Component({
  selector: 'app-org-set-tab-general',
  templateUrl: './org-set-tab-general.component.html',
  styleUrls: ['./org-set-tab-general.component.scss']
})
export class OrgSetTabGeneralComponent implements OnInit, OnDestroy {

  public canSave;
  public sub;
  public id;
  public mainImage;
  public company = {name: '', identifier: '', description: '', website: ''};

  constructor(private alertService: AlertService, private dataService: DataService,
              private route: ActivatedRoute, private envService: EnvService) {
  }

  ngOnInit(): void {
    this.sub = this.route.parent?.params.subscribe(params => {
      this.id = +params.id;
    });
    this.dataService.getCompany(this.id).subscribe(response => {
      this.company = response;
    });
  }

  getImageURL() {
    return this.envService.imageUrl + '/companies/' + this.id + '/logos/250x250';
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (!file || !file.type.startsWith('image/')) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.mainImage = reader.result.toString();
    };
  }

  saveChanges() {
    if (this.canSave && this.company && this.company.name && this.company.description) {
      if (this.mainImage) {
        // @ts-ignore
        this.company.image = this.mainImage;
      }
      this.dataService.putCompany(this.id, this.company).subscribe(response => {
        this.alertService.alert(
          {
            content: `Updated company information`,
            title: 'Updated',
            type: 'success'
          });
        this.canSave = false;
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}`,
            title: 'Something went wrong',
            type: 'error'
          });
      });
    } else {
      this.callBlockingSave();
    }
  }

  callBlockingSave() {
    const element = document.getElementById('saver');
    element.classList.add('blocking');
    setTimeout(() => {
      element.classList.remove('blocking');
    }, 1000);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

