import {Injectable, NgZone} from '@angular/core';
import * as auth from 'firebase/auth';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Router} from '@angular/router';
import {BehaviorSubject, from, switchMap, take} from 'rxjs';
import {AlertService} from '../services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any; // Save logged in user data
  token: any; // Save logged in user data
  authorization: any; // Save logged in user data

  private messageSource = new BehaviorSubject<any>(false);
  public changeReady = this.messageSource.asObservable();

  private messageSourceAuth = new BehaviorSubject<any>(false);
  public changeReadyAuth = this.messageSourceAuth.asObservable();

  constructor(
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private alertService: AlertService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        this.messageSource.next(this.userData);
        user.getIdTokenResult().then(token => {
          this.token = token.token;
          localStorage.setItem('token', this.token);
          this.authorization = token.claims.authorization;
          this.messageSourceAuth.next(this.authorization);
        });
        localStorage.setItem('user', JSON.stringify(this.userData));
        // tslint:disable-next-line:no-non-null-assertion
        JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
        localStorage.setItem('token', 'null');
        // tslint:disable-next-line:no-non-null-assertion
        JSON.parse(localStorage.getItem('user')!);
        // tslint:disable-next-line:no-non-null-assertion
        JSON.parse(localStorage.getItem('token')!);
      }
    });
  }

  // Sign in with email/password
  SignIn(email: string, password: string, to = null) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        // this.SetUserData(result.user);
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            if (!user.emailVerified) {
              this.router.navigate(['/auth/verify-email']);
            } else {
              if (!to) {
                this.router.navigate(['']);
              } else {
                this.router.navigate([to]);
              }
            }

          }
        });
      })
      .catch((error) => {
        this.alertService.alert(
          {
            content: `Username and password combination is not correct`,
            title: 'Not found',
            type: 'warning'
          });
      });
  }

  refreshToken(): Promise<string> {
    return this.afAuth.authState.pipe(
      take(1), // take only one emission
      switchMap((user) => {
        if (!user) {
          // no user is logged in
          return Promise.reject('No user logged in');
        }
        return from(user.getIdToken(true));
      })
    ).toPromise();
  }

  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        this.router.navigate(['/auth/verify-email']);
        // this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Send email verification when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification());
    // return this.afAuth.currentUser
    //   .then((u: any) => u.sendEmailVerification())
    //   .then(() => {
    //     this.router.navigate(['/auth/verify-email']);
    //   });
  }

  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .catch((error) => {
        return error;
      });
  }

  async updateProfile(displayName: string): Promise<void> {
    const user = await this.afAuth.currentUser;

    if (user) {
      await user.updateProfile({
        displayName
      });
    }
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null);
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.authorization = null;
      this.token = null;
      this.userData = null;
      this.router.navigate(['auth']);
    });
  }

}
