import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../auth/auth.service';
import {AlertService} from '../../../../services/alert.service';
import {LanguageService} from '../../../../services/language.service';

@Component({
  selector: 'app-prof-set-tab-general',
  templateUrl: './prof-set-tab-general.component.html',
  styleUrls: ['./prof-set-tab-general.component.scss']
})
export class ProfSetTabGeneralComponent implements OnInit {

  public user;
  public canSave;
  public selectedLanguage = 'en';
  constructor(public authService: AuthService, private alertService: AlertService, public ls: LanguageService) {
    this.authService.changeReady.subscribe(message => {
      if (message) {
        this.user = {email: message.email, displayName: message.displayName, verified: message.emailVerified || false};
      }
    });
  }

  ngOnInit(): void {
    this.selectedLanguage = localStorage.getItem('language') || 'en';
  }

  saveChanges() {
    if (this.user && this.user.displayName && this.canSave) {
      this.authService.updateProfile(this.user.displayName).then(() => {
        this.alertService.alert(
          {
            content: `Changed account information`,
            title: 'Changed',
            type: 'success'
          });
      }).catch((error) => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}: Could not change user information`,
            title: 'Error',
            type: 'error'
          });
      });
    } else {
      this.callBlockingSave();
    }

    if (this.selectedLanguage !== localStorage.getItem('language') || 'en') {
      this.ls.setLanguage(this.selectedLanguage);
    }
    this.canSave = false;
  }

  callBlockingSave() {
    const element = document.getElementById('saver');
    element.classList.add('blocking');
    setTimeout(() => {
      element.classList.remove('blocking');
    }, 1000);
  }

}
