<div class="network" *ngIf="networkMessage !== ''">
  <div class="network-offline" *ngIf="networkMessage === 'offline'">
    You're offline.
  </div>
  <div class="network-online" *ngIf="networkMessage === 'online'">
    You're back online.
  </div>
</div>
<div class="alerts">
  <template #alertContainer></template>
</div>
<app-header></app-header>
<div class="app">
  <router-outlet></router-outlet>
</div>

