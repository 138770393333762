import { Component } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent {

  public verifiedSent = false;

  constructor(private authService: AuthService,
              private titleService: Title) {
    this.titleService.setTitle(`Verify Email - Wireplate`);
  }

  sendEmail() {
    this.authService.SendVerificationMail().then(() => {
      this.verifiedSent = true;
    });
  }

  continue() {
    window.location.href = '/';
  }


}
