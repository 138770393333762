<div class="wrapper">
  <h2>Following</h2>
  <p>These are the companies and topics that you follow.</p>
  <div class="subscriptions">
    <div class="row subscription" *ngIf="!subscriptions">
      No subscriptions found that link to your account.
    </div>
    <a *ngFor="let sub of subscriptions" [routerLink]="[sub.type ? '/org' : '/topic', sub.sub_entity]">
      <div class="row subscription">
        <div class="col-10 info">
          <div *ngIf="sub.info">{{sub.info.name}}</div>
          <div *ngIf="loadingCycles[sub.sub_entity] && !sub.info">Loading information...</div>
          <div class="under">{{sub.id}}</div>
          <div class="under wrong" *ngIf="!sub.info && !loadingCycles[sub.sub_entity]">We could not find any information associated with this
            subscription. Something might have gone wrong.
          </div>
        </div>
        <div class="col-2">
          {{getSubType(sub.type)}}
        </div>
      </div>
    </a>
  </div>
</div>
