import { Component } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import * as icons from '@fortawesome/free-solid-svg-icons';
import * as rIcons from '@fortawesome/free-regular-svg-icons';
import {FaConfig} from '@fortawesome/angular-fontawesome';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public fa = icons;
  public far = rIcons;

  constructor(public authService: AuthService, faConfig: FaConfig, public ls: LanguageService) {
    faConfig.fixedWidth = true;
  }
}
