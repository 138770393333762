import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-editor-selector',
  templateUrl: './editor-selector.component.html',
  styleUrls: ['./editor-selector.component.scss']
})
export class EditorSelectorComponent {
  public fa = icons;
  public expanded = false;
  constructor(public ls: LanguageService) {
  }
}
