<div class="wrapper top">
  <div class="container">
    <h1>Wireplate</h1>
    <h3>Wireplate (Wire, as in newswire; Plate as in collection) allows organizations to bypass social media and conventional news
      outlets and lets them expand upon local blogs, by allowing them to create and share news with their target audience themselves.</h3>
  </div>
</div>

<div class="container article">
  <div class="content">
    <img src="assets/images/wireplate_color_bg.png">
    <h1>Wireplate creates value by allowing organizations to publish news directly to their target audience</h1>
    <div class="item">Only organizations take part in the news process on Wireplate, so no clutter from other influences is involved.
      Wireplate allows readers to read news for free. Readers can follow topics and organizations, and are therefore shown articles they are
      really interested in.
    </div>
  </div>
</div>

<div class="information">
  <div class="container content">
    <h2>Don't lose your meaning and essence with regular news outlets.</h2>
    <h2>Don't lose your news and statements.</h2>
    <h2>Don't lose your audience.</h2>
    <h2>Don't lose <span style="font-weight: bold">you</span>.</h2>
  </div>
</div>

<div class="container article">
  <div class="content">
    <img src="assets/images/1920x1080.png">
    <h1>Read news you are interested in, without having to find it yourself</h1>
    <div class="item">Companies tend to struggle to reach their DMU on social media. And that is not because there are no potential
      customers on there
      that are interested in that specific type of content. They do not know where to look. And who can blame them? If you are interested in
      news about law for example, would you check every single law firm's website, every single day? No! But because of this, you will not
      stay up-to-date with every bit of news coming from this field of work that you are interested in!
      <br>
      <br>Wireplate allows readers to receive news they want to read, and for organizations to reach the people they want to reach.
      Organizations can publish to a specific topic and their specific organization page.
      Your target audience does not have to go to every single law firm’s website to stay up-to-date with the latest developments, every law
      firm’s news is now on Wireplate. And they are served the best fitting news every single day.
    </div>
  </div>
</div>

<div class="information">
  <div class="container content">
    <h2>Wireplate allows you to control your own news. Don't rely on external news outlets to spread your word, and post news to your target
      audience directly.</h2>
  </div>
</div>


<div class="container basic statement">
  <div class="content">
    <app-frames-statement [isHeld]="true" [deleteStatementText]="false" [date]="today"
                          [title]="'Inform your followers of small updates by posting Statements. Statements are a quick and informal method of reaching your target audience.'"
                          [companyName]="'Wireplate'"></app-frames-statement>
  </div>
</div>

<div class="container subscription">
  <h2>Start now</h2>
  <h5>Choose a subscription that best fits your organization's needs.</h5>
  <a [routerLink]="['/org/new']">
    <div class="option" style="border: 2px solid #acacac; background: #acacac">
      <h4 style="float: right">Free</h4>
      <h4><span style="font-weight: bold">Free</span></h4>
      <p><span style="font-weight: bold">1</span> article per month</p>
    </div>
  </a>

  <a [routerLink]="['/org/new']">
    <div class="option" style="border: 2px solid #647dff; background: #647dff">
      <h4 style="float: right">Basic</h4>
      <h4>€<span style="font-weight: bold">499.99</span> per month <span style="font-size: 14px; opacity: .8">incl. 21% VAT</span></h4>
      <p><span style="font-weight: bold">5</span> articles per month</p>
      <p>
        <fa-icon [icon]="fa.faCheck"></fa-icon>
        Access to Statements
      </p>
    </div>
  </a>

  <a [routerLink]="['/org/new']">
    <div class="option" style="border: 2px solid #ad42ff; background: #ad42ff">
      <h4 style="float: right">Premium</h4>
      <h4>€<span style="font-weight: bold">649.99</span> per month <span style="font-size: 14px; opacity: .8">incl. 21% VAT</span></h4>
      <p><span style="font-weight: bold">8</span> articles per month</p>
      <p>
        <fa-icon [icon]="fa.faCheck"></fa-icon>
        Access to Statements
      </p>
    </div>
  </a>

  <a [routerLink]="['/org/new']">
    <div class="option" style="border: 2px solid #f405ff; background: #f405ff">
      <h4 style="float: right">Unlimited</h4>
      <h4>€<span style="font-weight: bold">899.99</span> per month <span style="font-size: 14px; opacity: .8">incl. 21% VAT</span></h4>
      <p><span style="font-weight: bold">Unlimited</span> articles per month</p>
      <p>
        <fa-icon [icon]="fa.faCheck"></fa-icon>
        Access to Statements
      </p>
    </div>
  </a>
</div>

<app-footer></app-footer>
