<div class="container" *ngIf="!frontpage">
  <h1 class="end">
    <fa-icon [icon]="fa.faSyncAlt"></fa-icon>
  </h1>
</div>
<div class="container" *ngIf="frontpage">
  <div class="item" *ngIf="frontpage && frontpage.featured.length !== 0">
    <div class="title">
      <h3>Featured <span class="mobile-hide">These articles are selected by Wireplate</span></h3>
    </div>
    <div class="row"  *ngIf="frontpage && frontpage.featured">
      <ng-container *ngFor="let result of frontpage.featured">
        <div class="item col-md">
          <a [routerLink]="['/article', articleLink(result.id, result.title)]" class="d-block text-decoration-none text-dark">
            <app-frames-main [title]="result.title" [date]="result.created" [id]="result.id" [version]="result.version" [companyName]="getCompanyName(result.company)"></app-frames-main>
          </a>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="item">
    <div class="title">
      <h3>Trending <span class="mobile-hide">These articles are rising in reads</span></h3>
    </div>
    <div class="alert alert-primary" role="alert" *ngIf="frontpage && frontpage.trending.length === 0">
      There are currently no trending articles
    </div>
    <div class="row" *ngIf="frontpage && frontpage.trending">
      <ng-container *ngFor="let result of frontpage.trending">
        <div class="item col-md-3">
          <a [routerLink]="['/article', articleLink(result.id, result.title)]" class="d-block text-decoration-none text-dark">
            <app-frames-image [title]="result.title" [date]="result.created" [id]="result.id" [version]="result.version" [companyName]="getCompanyName(result.company)"></app-frames-image>
          </a>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="item">
    <div class="title">
      <h3>Top stories today <span class="mobile-hide">Top stories published today</span></h3>
    </div>
    <div class="alert alert-primary" role="alert" *ngIf="frontpage && frontpage.top_stories_today.length === 0">
      There are currently no top stories published today
    </div>
    <div class="row" *ngIf="frontpage && frontpage.top_stories_today">
      <ng-container *ngFor="let result of frontpage.top_stories_today">
        <div class="item col-md-3">
          <a [routerLink]="['/article', articleLink(result.id, result.title)]" class="d-block text-decoration-none text-dark">
            <app-frames-image [title]="result.title" [date]="result.created" [id]="result.id" [version]="result.version" [companyName]="getCompanyName(result.company)"></app-frames-image>
          </a>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="item">
    <div class="title">
      <h3>Most read today <span class="mobile-hide">Most read today, published on any date</span></h3>
    </div>
    <div class="alert alert-primary" role="alert" *ngIf="frontpage && frontpage.most_read_today.length === 0">
      There are currently no most read articles for today
    </div>
    <div class="row" *ngIf="frontpage && frontpage.most_read_today">
      <ng-container *ngFor="let result of frontpage.most_read_today">
        <div class="item col-md-3">
          <a [routerLink]="['/article', articleLink(result.id, result.title)]" class="d-block text-decoration-none text-dark">
            <app-frames-image [title]="result.title" [date]="result.created" [id]="result.id" [version]="result.version" [companyName]="getCompanyName(result.company)"></app-frames-image>
          </a>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="item">
    <div class="title">
      <h3>Most read in the past 30 days <span class="mobile-hide">Most read this month, published on any date</span></h3>
    </div>
    <div class="alert alert-primary" role="alert" *ngIf="frontpage && frontpage.most_read_month.length === 0">
      There are currently no most read articles for this month
    </div>
    <div class="row" *ngIf="frontpage && frontpage.most_read_month">
      <ng-container *ngFor="let result of frontpage.most_read_month">
        <div class="item col-md-3">
          <a [routerLink]="['/article', articleLink(result.id, result.title)]" class="d-block text-decoration-none text-dark">
            <app-frames-image [title]="result.title" [date]="result.created" [id]="result.id" [version]="result.version" [companyName]="getCompanyName(result.company)"></app-frames-image>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>
