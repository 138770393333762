<div class="wrapper">
  <h2>General</h2>
  <div class="row">
    <div class="col-6 group">
      <label for="name"><span class="title">Name</span> <span class="faded">Your full name</span></label>
      <input type="text" id="name" [(ngModel)]="user.displayName" (change)="canSave = true">
    </div>
    <div class="col-6 group right">
      <label for="sub"><span class="title">Email</span> <span class="faded">{{user.verified ? 'Verified' : 'Unverified'}}</span></label>
      <input type="text" id="sub" [value]="user.email" disabled>
      <small>Your email can only be changed via service request</small>
    </div>
  </div>
  <div class="row">
    <div class="col group">
      <label for="langauge"><span class="title">Language</span> <span
        class="faded">Local setting, will not impact other devices</span></label>
      <select id="langauge" (change)="selectedLanguage = $event.target.value; canSave = true">
        <option [selected]="selectedLanguage === 'en'" value="en">English</option>
        <option [selected]="selectedLanguage === 'nl'" value="nl">Nederlands</option>
      </select>
      <small *ngIf="selectedLanguage !== 'en'">{{ls.getLanguageObject(selectedLanguage).NOTICE_language_disclaimer}}</small>
    </div>
  </div>
  <div class="saver" [ngClass]="{'active': canSave}" id="saver" (click)="saveChanges()">
    Save changes
  </div>
</div>
