import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, RouterOutlet} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {

  // public hasChild;
  public hasAuth;

  constructor(private titleService: Title, private router: Router,
              private routerOutlet: RouterOutlet, private authService: AuthService, public ls: LanguageService) {
    this.titleService.setTitle(`Wireplate - Business-to-Business News`);
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   this.hasChild = this.routerOutlet.isActivated;
    // });
    this.authService.changeReady.subscribe(auth => {
      this.hasAuth = auth;
    });
  }
}
