import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-topic-selector',
  templateUrl: './topic-selector.component.html',
  styleUrls: ['./topic-selector.component.scss']
})
export class TopicSelectorComponent implements OnInit, OnChanges {
  @Output() valueEvent = new EventEmitter();
  @Input() preSelectedTopicId;
  @Input() selectedTopic;

  public fa = icons;
  public topics;
  public displayTopics = [];
  public expanded = false;
  public searchValue = '';
  public searchingTimeout;
  public isLoading = false;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    if (!this.preSelectedTopicId) {
      this.dataService.getTopics(this.selectedTopic).subscribe(response => {
        this.topics = response;
        this.displayTopics = response;
        if (+this.selectedTopic && this.selectedTopic) {
          this.selectedTopic = this.topics.find(x => x.id === this.selectedTopic);
        }
      });
    } else {
      this.dataService.getTopic(this.preSelectedTopicId).subscribe(response => {
        this.selectedTopic = response;
      });
    }
  }

  searchTopics() {
    if (this.searchingTimeout) {
      clearTimeout(this.searchingTimeout);
    }

    this.displayTopics = this.topics.filter(item => item.flatname.indexOf(this.searchValue.toLowerCase()) !== -1);
    if (this.searchValue) {
      this.searchingTimeout = setTimeout(() => {
        this.isLoading = true;
        this.dataService.getSearchResults(this.searchValue, 'topics').subscribe(response => {
          this.displayTopics.push(...response);
          this.isLoading = false;
        });
        this.searchingTimeout = null;
      }, 1000);
    }
}

  selectTopic(topic) {
    this.selectedTopic = topic;
    this.valueEvent.emit(topic);
  }

  showSelector() {
    if (this.preSelectedTopicId) {
      return this.selectedTopic;
    } else {
      return true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.preSelectedTopicId) {
      this.ngOnInit();
    }
  }
}
