import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {EnvService} from './env.service';
import {Injectable} from '@angular/core';
import {catchError, count, flatMap, interval, Observable, of, retryWhen, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient, private env: EnvService) {
  }

  static handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    console.error('ERROR:');
    console.error(error);

    if (error.hasOwnProperty('error')) {
      if (error.error) {
        errorMessage = error.error.detail ? error.error.detail : error.error;
      } else {
        errorMessage = 'Unknown error occurred';
      }
    }

    if (error.error instanceof ErrorEvent) {
      console.error(`An unexpected error occurred: ${errorMessage}`);
    } else {
      console.error(`Backend returned code ${error.status}, body was: ${errorMessage}`);
    }

    return throwError(error);
  }

  public getPersonalHistory(): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/analytics/personal/history`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getFrontPage(): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/collections/front`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getArticleAnalytics(articleId): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/analytics/articles/${articleId}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getArticleInfoFromAnalytics(articleId): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/analytics/articles/${articleId}/info`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getSearchResults(keywords, selected = 'articles'): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/search?keywords=${keywords}&search=${selected}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getTopics(forcePresent = null): Observable<any> {
    if (forcePresent !== null) {
      return this.http.get(`${this.env.apiUrl}/topics?force=${forcePresent}`)
        .pipe(
          catchError(DataService.handleError)
        );
    }
    return this.http.get(`${this.env.apiUrl}/topics`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getTopic(topic): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/topics/${topic}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getRecommendations(): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/recommendations`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getCompanyWithIdentifier(identifier): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/identifiers/${identifier}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getCompany(company): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/companies/${company}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public postCompany(obj): Observable<any> {
    return this.http.post(`${this.env.apiUrl}/companies`, obj)
      .pipe(
        catchError(DataService.handleError)
      );
  }


  public putCompany(companyId, obj): Observable<any> {
    return this.http.put(`${this.env.apiUrl}/companies/${companyId}`, obj)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getSubscriptionLatestArticles(cursors, retrieved, limit = null): Observable<any> {
    if (limit !== null) {
      return this.http.post(`${this.env.apiUrl}/subscriptions/articles?cursors=${cursors}&limit=${limit}`, retrieved)
        .pipe(
          catchError(DataService.handleError)
        );
    }
    return this.http.post(`${this.env.apiUrl}/subscriptions/articles?cursors=${cursors}`, retrieved)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getTopicLatestArticles(topicId, limit, cursor = null): Observable<any> {
    if (cursor) {
      return this.http.get(`${this.env.apiUrl}/topics/${topicId}/articles?limit=${limit}&cursor=${cursor}`)
        .pipe(
          catchError(DataService.handleError)
        );
    }
    return this.http.get(`${this.env.apiUrl}/topics/${topicId}/articles?limit=${limit}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getCompanyLatestArticles(companyId, limit, cursor = null): Observable<any> {
    if (cursor) {
      return this.http.get(`${this.env.apiUrl}/companies/${companyId}/articles?limit=${limit}&cursor=${cursor}`)
        .pipe(
          catchError(DataService.handleError)
        );
    }
    return this.http.get(`${this.env.apiUrl}/companies/${companyId}/articles?limit=${limit}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getLatestArticles(limit, cursor = null): Observable<any> {
    if (cursor) {
      return this.http.get(`${this.env.apiUrl}/articles?limit=${limit}&cursor=${cursor}`)
        .pipe(
          catchError(DataService.handleError)
        );
    }
    return this.http.get(`${this.env.apiUrl}/articles?limit=${limit}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getArticle(article): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/articles/${article}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public deleteArticle(article): Observable<any> {
    return this.http.delete(`${this.env.apiUrl}/articles/${article}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public postArticle(companyId, content): Observable<any> {
    return this.http.post(`${this.env.apiUrl}/companies/${companyId}/articles`, content)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public putArticle(articleId, content): Observable<any> {
    return this.http.put(`${this.env.apiUrl}/articles/${articleId}`, content)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getStatement(statement): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/statements/${statement}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public deleteStatement(statement): Observable<any> {
    return this.http.delete(`${this.env.apiUrl}/statements/${statement}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public postStatement(companyId, content): Observable<any> {
    return this.http.post(`${this.env.apiUrl}/companies/${companyId}/statements`, content)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getCompanyLatestStatements(companyId, limit, cursor = null): Observable<any> {
    if (cursor) {
      return this.http.get(`${this.env.apiUrl}/companies/${companyId}/statements?limit=${limit}&cursor=${cursor}`)
        .pipe(
          catchError(DataService.handleError)
        );
    }
    return this.http.get(`${this.env.apiUrl}/companies/${companyId}/statements?limit=${limit}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getCompanyPaymentSubscription(companyId): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/companies/${companyId}/payments/subscriptions`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getCompanyPaymentHistory(companyId): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/companies/${companyId}/payments/history`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getPermissionsByCompany(companyId): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/companies/${companyId}/permissions`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public postPermissionsByCompany(companyId, user): Observable<any> {
    return this.http.post(`${this.env.apiUrl}/companies/${companyId}/permissions`, user)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public putPermissionsByCompany(companyId, user): Observable<any> {
    return this.http.put(`${this.env.apiUrl}/companies/${companyId}/permissions`, user)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public deletePermissionsByCompany(companyId, user): Observable<any> {
    return this.http.request('delete', `${this.env.apiUrl}/companies/${companyId}/permissions`, {body: user})
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getSubscriptionForUser(): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/subscriptions`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public getSubscriptionByEntityId(entityId): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/subscriptions/${entityId}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public deleteSubscriptionByEntityId(entityId): Observable<any> {
    return this.http.delete(`${this.env.apiUrl}/subscriptions/${entityId}`)
      .pipe(
        catchError(DataService.handleError)
      );
  }

  public postSubscriptionWithType(entityId, type): Observable<any> {
    return this.http.post(`${this.env.apiUrl}/subscriptions`, {sub_entity: entityId, type})
      .pipe(
        catchError(DataService.handleError)
      );
  }
}
