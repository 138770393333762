<div class="wrapper">
  <h2>Work Profile</h2>
  <div class="alert alert-warning" role="alert">
    This feature is currently not available in your region. We are working hard to fix this issue.
  </div>
  <p>This is your personal information that is used by Wireplate to serve you with fitting articles. Changes you make here have an impact on your <a [routerLink]="['/you']">For You</a> page.
    You can read more about how we store and use data in our <a [routerLink]="['/privacy-policy']">privacy policy</a>.</p>
  <small>Missing some options? Please use our <a href="https://github.com/Inphent/wireplate-support" target="_blank">Issue Tracker on Github</a>.</small>
  <div class="row" style="margin-top: 25px">
    <div class="col-md-6 group">
      <label for="title"><span class="title">Job Title</span> <span class="faded">Your job title</span></label>
      <input type="text" id="title">
    </div>
    <div class="col-md-6 group right">
      <label for="sub"><span class="title">Years Experience</span> <span class="faded">Total years you have worked in your field</span></label>
      <input type="number" id="sub">
    </div>
  </div>
  <div class="row" style="margin-top: 25px">
    <div class="col group">
      <label for="degree"><span class="title">Degree</span> <span class="faded">Your full degree</span></label>
      <input type="text" id="degree">
    </div>
  </div>
  <div class="saver" [ngClass]="{'active': canSave}" id="saver" (click)="saveChanges()">
    Save changes
  </div>
</div>
