import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'textarea[autoresize]'
})
export class AutoresizeDirective {
  @Input() wantsSingleLine = false;

  constructor(private el: ElementRef) {
    setTimeout(() => this.resize(), 0);
  }

  @HostListener('input')
  onInput() {
    this.resize();
  }

  private resize() {
    this.el.nativeElement.style.overflow = 'hidden';
    this.el.nativeElement.style.height = 'auto';

    if (this.wantsSingleLine) {
      // Set an initial height that accommodates a single line of text
      this.el.nativeElement.style.height = '1px';
    }

    // Now set the actual calculated height
    this.el.nativeElement.style.height = this.el.nativeElement.scrollHeight + 'px';
  }

}
