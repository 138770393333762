import { Component } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public fa = icons;

  constructor(public ls: LanguageService) {
  }
}
