import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../../auth/auth.service';
import {DataService} from '../../../../services/data.service';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {AlertService} from '../../../../services/alert.service';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-org-set-tab-roles',
  templateUrl: './org-set-tab-roles.component.html',
  styleUrls: ['./org-set-tab-roles.component.scss']
})
export class OrgSetTabRolesComponent implements OnInit, OnDestroy {

  public permissions;
  public newUserUID;
  public sub;
  public id;

  public fa = icons;
  public loading = false;
  constructor(public authService: AuthService, private dataService: DataService,
              private alertService: AlertService, private route: ActivatedRoute) {
  }


  ngOnInit() {
    this.sub = this.route.parent?.params.subscribe(params => {
      this.id = +params.id;
      this.getPermissions();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getPermissions() {
    this.loading = true;
    this.dataService.getPermissionsByCompany(this.id).subscribe(response => {
      this.permissions = response;
      this.loading = false;
    }, error => {
      if (error.status === 403) {
        this.alertService.alert(
          {
            content: `You are not allowed to view this information. If you think this is a mistake, please contact the administrator or owner of this organisation`,
            title: 'Forbidden',
            type: 'warning'
          });
      } else {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}`,
            title: 'Something went wrong',
            type: 'error'
          });
      }
    });
  }

  changePermission(userId, event) {
    // tslint:disable-next-line:max-line-length
    this.dataService.putPermissionsByCompany(this.id, {user: userId, permission: event.target.value}).subscribe(response => {
      this.alertService.alert(
        {
          content: `Changed user permissions`,
          title: 'Changed',
          type: 'success'
        });
    }, error => {
      this.alertService.alert(
        {
          content: `${error.error.detail || error.error}`,
          title: 'Could not change user permissions',
          type: 'error'
        });
    });
  }

  newPermission() {
    // tslint:disable-next-line:max-line-length
    this.dataService.postPermissionsByCompany(this.id, {user: this.newUserUID, permission: 'Editor'}).subscribe(response => {
      this.alertService.alert(
        {
          content: `Created user permissions`,
          title: 'Created',
          type: 'success'
        });
      this.getPermissions();
    }, error => {
      this.alertService.alert(
        {
          content: `${error.error.detail || error.error}`,
          title: 'Could not create user permissions',
          type: 'error'
        });
    });
  }

  deletePermission(userId) {
    this.dataService.deletePermissionsByCompany(this.id, {user: userId}).subscribe(response => {
      this.alertService.alert(
        {
          content: `Deleted user permissions`,
          title: 'Deleted',
          type: 'success'
        });
      this.getPermissions();
    }, error => {
      this.alertService.alert(
        {
          content: `${error.error.detail || error.error}`,
          title: 'Could not delete user permissions',
          type: 'error'
        });
    });
  }

}
