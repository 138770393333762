import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(value: string): string {
    switch (value.toUpperCase()) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'GBP':
        return '£';
      case 'JPY':
        return '¥';
      case 'CAD':
        return 'C$';
      case 'AUD':
        return 'A$';
      case 'CHF':
        return 'Fr';
      case 'CNY':
        return '¥';
      case 'SEK':
        return 'kr';
      case 'NZD':
        return 'NZ$';
      case 'KRW':
        return '₩';
      case 'SGD':
        return 'S$';
      case 'HKD':
        return 'HK$';
      case 'INR':
        return '₹';
      default:
        return value + ' ';
    }
  }
}
