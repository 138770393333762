import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountNumberPipe'
})
export class AmountNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 1000 && value < 1000000) {
      const formattedValue = (value / 100).toFixed(2);
      const separatorIndex = formattedValue.indexOf('.');
      const wholeNumber = formattedValue.slice(0, separatorIndex);
      const decimalPart = formattedValue.slice(separatorIndex);
      return `${wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${decimalPart}`;
    } else {
      return value.toString();
    }
  }
}
