import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../auth/auth.service';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-statement-holder',
  templateUrl: './statement-holder.component.html',
  styleUrls: ['./statement-holder.component.scss']
})
export class StatementHolderComponent implements OnInit {
  public id;
  public statement;
  public company;
  public canEdit = false;
  public isDeleting = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private route: ActivatedRoute, private dataService: DataService,
              private authService: AuthService, private alertService: AlertService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.id = data.statement.id;
      this.statement = data.statement;
      this.authService.changeReadyAuth.subscribe(auth => {
        this.canEdit = auth && auth.filter(e => e.company.toString() === this.statement.company.toString()).length > 0;
      });
      this.dataService.getCompany(this.statement.company).subscribe(r => {
        this.company = r;
      });
    });
  }

  deleteStatement() {
    if (this.isDeleting && window.confirm('Are you sure you want to delete this Statement? You cannot undo this action.')) {
      this.dataService.deleteStatement(this.id).subscribe(response => {
        this.alertService.alert(
          {
            content: `Deleted statement`,
            title: 'Deleted',
            type: 'success'
          });
        this.router.navigate(['']);
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}`,
            title: 'Could not delete statement',
            type: 'error'
          });
      });
    } else {
      this.isDeleting = false;
    }
  }

}
