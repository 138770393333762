import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {Title} from '@angular/platform-browser';
import {AuthService} from '../../auth/auth.service';
import {AlertService} from '../../services/alert.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public localLogin = false;
  public loginFormGroup: FormGroup;
  public localFormGroup: FormGroup;
  public fa = icons;
  public to = {actual: '', display: ''};

  constructor(private formBuilder: FormBuilder,
              private titleService: Title,
              private authService: AuthService,
              private alertService: AlertService,
              private route: ActivatedRoute) {
    this.titleService.setTitle(`Authentication - Wireplate`);
    this.loginFormGroup = this.formBuilder.group(
      {
        email: ['', Validators.required]
      }
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.to.display = params.to || 'wireplate.com';
      this.to.actual = params.to;
    });
  }

  email() {
    if (this.loginFormGroup.valid && this.loginFormGroup.dirty) {
      this.localFormGroup = this.formBuilder.group(
        {
          email: [this.loginFormGroup.value.email, Validators.required],
          password: ['', Validators.required]
        }
      );
      this.localLogin = true;
    }
  }


  login() {
    if (this.localFormGroup.valid && this.localFormGroup.dirty) {
      this.authService.SignIn(this.localFormGroup.value.email, this.localFormGroup.value.password, this.to.actual);
    }
  }

  forgotPassword() {
    if (this.localFormGroup.value.email) {
      this.authService.ForgotPassword(this.localFormGroup.value.email).then(r => {
        this.alertService.alert(
          {
            content: `Reset email send to ${this.localFormGroup.value.email}`,
            title: 'Forgot email',
            type: 'info'
          });
      });
    }
  }
}
