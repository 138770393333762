import {Component, HostListener} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {AlertService} from '../../../../services/alert.service';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-landing-front',
  templateUrl: './landing-front.component.html',
  styleUrls: ['./landing-front.component.scss']
})
export class LandingFrontComponent {
  public fa = icons;
  public frontpage;
  public companies = [];

  constructor(private dataService: DataService, private alertService: AlertService) {
    this.loadResults();
  }

  loadResults() {

    this.dataService.getFrontPage().subscribe(response => {
      this.frontpage = response;
      // tslint:disable-next-line:forin
      for (const key in response) {
        Array.from(new Set(response[key].map(obj => obj.company))).forEach(company => {
          // @ts-ignore
          if (!this.companies[company]) {
            this.dataService.getCompany(company).subscribe(cRe => {
              this.companies[cRe.id] = cRe;
            });
          }
        });
      }
    }, error => {
      this.alertService.alert(
        {
          content: `${error.error.detail || error.error}: Could not load articles`,
          title: 'Something went wrong',
          type: 'error'
        });
    });

  }

  getCompanyName(id) {
    if (this.companies && this.companies[id]) {
      return this.companies[id].name;
    }
    return '-';
  }

  articleLink(id, title) {
    return id + '-' + title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  }
}
