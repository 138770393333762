<div class="skel-loading selector" *ngIf="preSelectedTopicId && !selectedTopic"></div>
<div class="selector" *ngIf="showSelector()">
  <a [routerLink]="['/topic/' + preSelectedTopicId + '-' + selectedTopic.name]" *ngIf="preSelectedTopicId">
    <div class="display pre" [ngStyle]="{'background': selectedTopic ? selectedTopic.color : '#bbbbbb'}">
      <span [title]="selectedTopic.name">{{selectedTopic.name}}</span>
    </div>
  </a>
  <div class="display" *ngIf="!preSelectedTopicId" (click)="expanded = !expanded" [ngStyle]="{'background': selectedTopic ? selectedTopic.color : '#bbbbbb'}" [ngClass]="{'pre': preSelectedTopicId}">
    <span [title]="selectedTopic ? selectedTopic.name : 'Select topic'">{{selectedTopic ? selectedTopic.name : 'Select topic'}}</span>
    <fa-icon [hidden]="preSelectedTopicId" [icon]="expanded ? fa.faChevronUp : fa.faChevronDown"></fa-icon>
  </div>
  <div class="options" *ngIf="expanded && topics && !preSelectedTopicId">
    <input placeholder="Search for more topics..." [(ngModel)]="searchValue" (input)="searchTopics()">
    <div class="item" *ngFor="let topic of displayTopics" [ngStyle]="{'background': topic.color}" [ngClass]="{'selected':  (selectedTopic == topic) || (selectedTopic && (selectedTopic.id == topic.id))}" (click)="selectTopic(topic); expanded = false">
      {{topic.name}}
    </div>
    <div class="loading" *ngIf="searchingTimeout || isLoading"><fa-icon [icon]="fa.faSyncAlt"></fa-icon></div>
  </div>
</div>
