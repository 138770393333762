<div class="selector">
  <div class="display" (click)="expanded = !expanded">
    <span>{{ls.lo().Create}}</span>
    <fa-icon [icon]="expanded ? fa.faChevronUp : fa.faChevronDown"></fa-icon>
  </div>
  <div class="options" *ngIf="expanded">
    <a [routerLink]="['/edit']" (click)="expanded = false">
      <div class="item">
        {{ls.lo().Article}}
      </div>
    </a>
    <a [routerLink]="['/statement']" (click)="expanded = false">
      <div class="item">
        Statement
      </div>
    </a>
  </div>
</div>
