<div class="container">
  <div class="control">
    <app-company-selector (valueEvent)="company = $event.id"></app-company-selector>
    <div class="save">
      <button [ngClass]="{'saveable': statementInput.value.length <= 250 && statementInput.value.length > 0 && company}" (click)="save()">
        <span *ngIf="!saving">Save</span>
        <fa-icon *ngIf="saving" [icon]="fa.faSyncAlt"></fa-icon>
      </button>
    </div>
  </div>
  <div class="text">
    <textarea class="input title" placeholder="Statement here..." autoresize [(ngModel)]="statement" style="resize: none" id="title" #statementInput (input)="statement = normalize(statement)"></textarea>
  </div>
  <div style="right: 0; position: absolute"><small [ngStyle]="{'color': statementInput.value.length > 250 ? '#ff4545' : 'black'}">{{statementInput.value.length}}/250 characters</small></div>
</div>
<app-footer></app-footer>
