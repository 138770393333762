<div class="container" *ngIf="company">
  <div class="information">
    <div class="image">
      <img [src]="getImageURL()">
    </div>
    <div class="title">
      <h1>{{company.name}}
        <fa-icon [icon]="fa.faCheckCircle"></fa-icon>
      </h1>
      <h5>@{{company.identifier}}</h5>
    </div>
  </div>
  <div class="text">
    <div class="description">
      {{company.description}}</div>
  </div>
  <div class="control">
    <button class="main" [ngClass]="{'active': isSubscribed}" *ngIf="isSubscribed !== null"
            (click)="subUnsub()">
      <span *ngIf="!isLoadingSubscribed">{{isSubscribed ? ls.lo().Following : ls.lo().Follow}}</span>
      <fa-icon [icon]="fa.faSyncAlt" class="loading" *ngIf="isLoadingSubscribed"></fa-icon>
    </button>
    <button class="main skel-loading button" *ngIf="isSubscribed === null">Follow</button>
    <a [href]="company.website" target="_blank" *ngIf="company.website">
      <button>{{ls.lo().Visit_website}}
        <fa-icon [icon]="fa.faExternalLink"></fa-icon>
      </button>
    </a>
<!--    <button>{{ls.lo().More}}</button>-->
    <a [routerLink]="['/org/' + id + '/settings']" *ngIf="canEdit">
      <button>{{ls.lo().Admin_Settings}}</button>
    </a>
  </div>
  <div class="articles">
    <h3>{{ls.lo().Latest_releases}}</h3>
    <div class="row">
      <ng-container *ngFor="let article of latestArticles">
        <div class="col-md-3">
          <a [routerLink]="['/article', articleLink(article.id, article.title)]" class="d-block text-decoration-none text-dark">
            <app-frames-image [title]="article.title" [date]="article.created" [id]="article.id"
                              [version]="article.version"></app-frames-image>
          </a>
        </div>
      </ng-container>
    </div>
    <div class="extend" (click)="loadLatestArticles()"
         *ngIf="(latestCursor || latestCursor === undefined) && latestArticles.length !== 0">
      <p *ngIf="!isLoadingLatest">Load more {{company ? company.name + ' ' : ''}}articles</p>
      <fa-icon [icon]="fa.faSyncAlt" *ngIf="isLoadingLatest"></fa-icon>
    </div>
  </div>
  <div class="statements">
    <h3>{{ls.lo().Latest_statements}}</h3>
    <p>{{ls.lo().NOTICE_statements}}</p>
    <div class="row">
      <ng-container *ngFor="let statement of latestStatements">
        <div class="col-md-3">
          <a [routerLink]="['/statement', statement.id]" class="d-block text-decoration-none text-dark">
            <app-frames-statement [isHeld]="false" [deleteStatementText]="true" [date]="statement.created" [title]="statement.title"
                                  *ngIf="statement"></app-frames-statement>
          </a>
        </div>
      </ng-container>
    </div>
    <div class="extend" (click)="loadLatestStatements()"
         *ngIf="(latestStatementsCursor || latestStatementsCursor === undefined) && latestStatements.length !== 0">
      <p *ngIf="!isLoadingLatestStatements">Load more {{company ? company.name + ' ' : ''}}statements</p>
      <fa-icon [icon]="fa.faSyncAlt" *ngIf="isLoadingLatestStatements"></fa-icon>
    </div>
  </div>
</div>
<app-footer></app-footer>
