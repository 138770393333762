import {EnvService} from './env.service';

interface WindowEnv {
  __env?: any;
}

export const EnvServiceFactory = () => {

  const env: EnvService = new EnvService();
  if (typeof window !== 'undefined') {
    const browserWindow: WindowEnv = (window || {}) as WindowEnv;
    const browserWindowEnv: any = browserWindow.__env || {};

    for (const key in browserWindowEnv) {
      if (browserWindowEnv.hasOwnProperty(key)) {
        env[key] = browserWindow.__env[key];
      }
    }
    return env;
  }

  env.apiUrl = 'https://api.wireplate.com';
  env.imageUrl = 'https://storage.googleapis.com/inphent-p-wireplate';
  env.firebaseConfig = {
    apiKey: 'AIzaSyDePzdQW1TWmA3VGO3plSuDtfSoj2RVcQ4',
    authDomain: 'inphent-p-wireplate.firebaseapp.com',
    projectId: 'inphent-p-wireplate',
    storageBucket: 'inphent-p-wireplate.appspot.com'
  };
  return env;
};

export const EnvServiceProvider = {
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};
