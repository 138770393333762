export class EnvService {

  public apiUrl = '';
  public imageUrl = '';
  public firebaseConfig = {};

  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  public enableDebug = true;

}
