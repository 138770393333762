import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[delayLoad]'
})
export class delayloadDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, 'visibility', 'hidden');
    setTimeout(() => {
      this.renderer.setStyle(this.el.nativeElement, 'visibility', 'visible');
    }, 200); // 200 milliseconds
  }
}
