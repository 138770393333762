import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DataService} from '../../services/data.service';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {AuthService} from '../../auth/auth.service';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent implements OnInit {
  public fa = icons;
  public topicId;
  public latestArticles = [];
  public latestCursor;
  public isLoadingLatest = false;
  public isLoadingSubscribed = false;
  public isSubscribed = null;
  public companies = {};

  constructor(private route: ActivatedRoute, private titleService: Title, private router: Router,
              private dataService: DataService, private alertService: AlertService, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.latestCursor = null;
    this.latestArticles = [];
    this.isLoadingLatest = false;
    const topic = this.route.snapshot.paramMap.get('id');
    this.topicId = +topic.split('-')[0];
    this.titleService.setTitle(`Topic ${topic.split('-')[1] || ''} - Wireplate`);
    this.loadLatestArticles();

    this.isLoadingSubscribed = true;
    this.dataService.getSubscriptionByEntityId(this.topicId).subscribe(r => {
      // tslint:disable-next-line:triple-equals
      this.isSubscribed = r.sub_entity == this.topicId;
      this.isLoadingSubscribed = false;
    }, error => {
      this.isSubscribed = false;
      this.isLoadingSubscribed = false;
    });
  }

  getCompanyName(id) {
    if (this.companies && this.companies[id]) {
      return this.companies[id].name;
    }
    return '-';
  }

  articleLink(id, title) {
    return id + '-' + title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  }

  selectedTopic(event) {
    const url = `/topic/${event.id}-${event.name}`;
    this.router.navigate([url]).then(() => {
      this.ngOnInit();
    });
  }

  loadLatestArticles() {
    if (!this.isLoadingLatest) {
      this.isLoadingLatest = true;
      this.dataService.getTopicLatestArticles(this.topicId, 8, this.latestCursor).subscribe(response => {
        this.latestArticles.push(...response.articles);
        Array.from(new Set(response.articles.map(obj => obj.company))).forEach(company => {
          // @ts-ignore
          if (!this.companies[company]) {
            this.dataService.getCompany(company).subscribe(cRe => {
              this.companies[cRe.id] = cRe;
            });
          }
        });
        this.latestCursor = response.cursor;
        this.isLoadingLatest = false;
      });
    }
  }

  subUnsub() {
    if (this.isSubscribed === null) {
      return;
    }

    if (!this.authService.isLoggedIn) {
      this.router.navigate(['auth']);
    }

    this.isLoadingSubscribed = true;

    if (this.isSubscribed) {
      this.dataService.deleteSubscriptionByEntityId(this.topicId).subscribe(response => {
        this.alertService.alert(
          {
            content: `You are no longer following this topic`,
            title: 'Unfollow',
            type: 'success'
          });
        this.isSubscribed = false;
        this.isLoadingSubscribed = false;
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}: Could not unfollow this topic`,
            title: 'Something went wrong',
            type: 'error'
          });
      });
    } else if (!this.isSubscribed) {
      this.dataService.postSubscriptionWithType(this.topicId, 0).subscribe(response => {
        this.alertService.alert(
          {
            content: `You are following this topic`,
            title: 'Following',
            type: 'success'
          });
        this.isSubscribed = true;
        this.isLoadingSubscribed = false;
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}: Could not follow this topic`,
            title: 'Something went wrong',
            type: 'error'
          });
      });
    }
  }
}
