<div class="wrapper">
  <div class="login">
    <img src="assets/images/dots.png" class="login-image" draggable="false">
    <div class="form-initial">
      <div class="login-username">
        <form [formGroup]="loginFormGroup">
          <div class="login-username-input">
            <input type="text"
                   placeholder="Your email"
                   autocomplete="on"
                   [ngClass]="{'alerting': loginFormGroup.controls['email'].invalid && loginFormGroup.controls['email'].touched}"
                   name="username"
                   formControlName="email">
          </div>
          <div class="login-username-input">
            <input type="password"
                   autocomplete="on"
                   [ngClass]="{'alerting': loginFormGroup.controls['password'].invalid && loginFormGroup.controls['password'].touched}"
                   name="password"
                   autofocus
                   (keydown.enter)="signup()"
                   formControlName="password">
          </div>
          <div class="login-username-btn" (click)="signup()">
            <button>Sign up</button>
          </div>
        </form>
      </div>
      OR
      <div class="break"></div>
      <div class="login-buttons">
        <div class="login-buttons-btn blocked">
            <span class="login-buttons-btn-img">
                <img src="assets/images/google.png">
            </span>
          Sign up with Google
        </div>
        <div class="login-buttons-btn blocked">
            <span class="login-buttons-btn-img">
                <img src="assets/images/microsoft.png">
            </span>
          Sign up with Microsoft
        </div>
      </div>
    </div>
    <div class="login-notice">
      After signing up, you will be redirected to<br>
      <div class="login-notice-url" id="noticeUrl">wireplate.com</div>
    </div>
  </div>
  <div class="policies">
    <a [routerLink]="['/privacy-policy']">Privacy Policy</a> • <a [routerLink]="['/terms-of-use']">Terms of Use</a>
  </div>
</div>
<div class="products">
  <span>Solutions with strategy.</span>
  KVK 88169227 <a href="https://inphent.com/" target="_blank">Inphent</a>. All rights reserved.
</div>
