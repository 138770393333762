<div class="container">
  <div class="row">
    <ng-container *ngFor="let result of results">
      <div class="item col-md-3">
        <a [routerLink]="['/article', articleLink(result.id, result.title)]" class="d-block text-decoration-none text-dark" *ngIf="!result.is_statement">
          <app-frames-image [title]="result.title" [date]="result.created" [id]="result.id" [version]="result.version" [companyName]="getCompanyName(result.company)"></app-frames-image>
        </a>
        <a [routerLink]="['/statement', result.id]" class="d-block text-decoration-none text-dark" *ngIf="result.is_statement">
          <app-frames-statement [isHeld]="false" [deleteStatementText]="false" [date]="result.created" [title]="result.title" [companyName]="getCompanyName(result.company)"></app-frames-statement>
        </a>
      </div>
    </ng-container>
  </div>
</div>
<div class="end" *ngIf="results.length === 0 && !isLoadingNew">
  <h5>No articles found</h5>
</div>
<div class="end" *ngIf="isLoadingNew && results.length === 0">
  <h1><fa-icon [icon]="fa.faSyncAlt"></fa-icon></h1>
</div>
<div class="end" *ngIf="canGetMore()">
  <p (click)="loadResults()" *ngIf="!isLoadingNew">See more articles</p>
  <fa-icon [icon]="fa.faSyncAlt" *ngIf="isLoadingNew"></fa-icon>
</div>
