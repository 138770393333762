<div class="wrapper">
  <h2>General</h2>
  <div class="row">
    <div class="col-6 group">
      <label for="name"><span class="title">Name</span> <span class="faded">Public display name</span></label>
      <input type="text" id="name" [(ngModel)]="company.name" (input)="canSave = true">
    </div>
    <div class="col-6 group right">
      <label for="sub"><span class="title">Identifier</span> <span class="faded">Unique identifier code</span></label>
      <input type="text" id="sub" [value]="company.identifier" disabled>
      <small>Your identifier can only be changed via service request</small>
    </div>
  </div>
  <div class="row desc">
    <div class="group">
      <label for="name"><span class="title">Website</span> <span class="faded">Public website</span></label>
      <input type="text" id="website" [(ngModel)]="company.website" (input)="canSave = true">
    </div>
  </div>
  <div class="row desc">
    <div class="group">
      <label for="name"><span class="title">Description</span> <span class="faded">Public description</span></label>
      <textarea id="desc" [(ngModel)]="company.description" (input)="canSave = true"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-2 group logo">
      <img [src]="mainImage ? mainImage : getImageURL()">
    </div>
    <div class="col-10 group">
      <label for="name"><span class="title">Logo</span> <span class="faded">250x250 recommended</span></label>
      <input type="file" id="logo" (change)="onFileSelected($event); canSave = true" accept=".png, .jpg, .jpeg">
      <small *ngIf="mainImage" style="color: #ff0000">It can take up to 24 hours for the image change to show up.</small>
    </div>
  </div>
  <div class="saver" [ngClass]="{'active': canSave}" id="saver" (click)="saveChanges()">
    Save changes
  </div>
</div>
