import { Component } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {FaConfig} from '@fortawesome/angular-fontawesome';
import {AuthService} from '../../auth/auth.service';
import * as rIcons from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public fa = icons;
  public hasOrg = false;

  constructor(faConfig: FaConfig, private authService: AuthService) {
    faConfig.fixedWidth = true;
    this.authService.changeReadyAuth.subscribe(auth => {
      this.hasOrg = auth && auth.length > 0;
    });
  }

  resetScroll() {
    window.scrollTo(0, 0);
  }
}
