<div class="container">
  <div class="alert alert-primary">Wireplate does not support premium features in your region yet. Please check back later if you are interested in the premium options.
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-12" style="padding-bottom: 80px">
      <div class="row">
        <div class="col-12 group">
          <label for="name">
            <span class="title">Name</span>
            <span class="faded">Public display name</span>
          </label>
          <input type="text" id="name" [(ngModel)]="company.name" (input)="updateIdentifier()">
        </div>
        <div class="col-12 group">
          <label for="sub">
            <span class="title">Code</span>
            <span class="faded">Unique identifier code</span>
          </label>
          <input type="text" id="sub" [(ngModel)]="company.identifier" (ngModelChange)="company.identifier = $event.toLowerCase()">
          <small>Used by users to find you. Can only be changed later by service request.</small>
        </div>
      </div>
      <div class="row desc">
        <div class="col-12 group">
          <label for="desc"><span class="title">Description</span> <span class="faded">Public description</span></label>
          <textarea id="desc" [(ngModel)]="company.description"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-2 group logo">
          <img [src]="company.image" *ngIf="company.image">
        </div>
        <div class="col-10 group">
          <label for="name"><span class="title">Logo</span> <span class="faded">250px by 250px</span></label>
          <input type="file" id="logo" (change)="onFileSelected($event);" accept=".png, .jpg, .jpeg">
        </div>
      </div>
      <div class="form-check" style="margin: 25px 0">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="company.agree">
        <small>I agree to the Wireplate <a [routerLink]="['/terms-of-use']">terms of use</a>. I understand that organisation data will be
          stored and used conform the <a [routerLink]="['/privacy-policy']">privacy policy</a>. I hereby declare that I am a part of this
          organisation and that I am authorized to act on its behalf, both for creating as for managing this organisation on Wireplate.
          Neither Wireplate nor <a href="https://inphent.com/">Inphent</a> (In any form; legal or real entity) are responsible for my
          actions on the Wireplate platform.</small>
      </div>
      <button class="saver one" [ngClass]="{'active': this.canSave()}" id="saver" (click)="saveChanges()">
        <span *ngIf="!saving">Create organization</span>
        <fa-icon *ngIf="saving" [icon]="fa.faSyncAlt"></fa-icon>
      </button>
    </div>
    <div class="col-md-6 col-sm-12">
      <h5>Subscription plan</h5>
      <small>The subscription plan you want to use. The selected subscription plan can be changed in the organisational settings, but
        additional charges might apply.</small>
      <small><span style="font-weight: bold">The subscription plans are subject to change at any time.</span></small>
      <div *ngIf="!company.sub_info.subscription">
        <a href="mailto:payments@wireplate.com" target="_blank" style="position: relative; top: 15px">Don't see a fitting plan? Contact
          us.</a>
        <div class="option" style="border: 2px solid #acacac; background: #acacac"
             [ngClass]="{'selected': company.sub_info.subscription === 'free'}" (click)="onSubscriptionSelect('free')">
          <h4 style="float: right">Free</h4>
          <h4><span style="font-weight: bold">Free</span></h4>
          <p><span style="font-weight: bold">1</span> article per month</p>
        </div>
        <div class="option disabled" style="border: 2px solid #647dff; background: #647dff"
             [ngClass]="{'selected': company.sub_info.subscription === 'basic'}" (click)="onSubscriptionSelect('basic')">
          <h4 style="float: right">Basic</h4>
          <h4>€<span style="font-weight: bold">499.99</span> per month <span style="font-size: 14px; opacity: .8">incl. 21% VAT</span></h4>
          <p><span style="font-weight: bold">5</span> articles per month</p>
          <p>
            <fa-icon [icon]="fa.faCheck"></fa-icon>
            Access to Statements
          </p>
        </div>
        <div class="option disabled" style="border: 2px solid #ad42ff; background: #ad42ff"
             [ngClass]="{'selected': company.sub_info.subscription === 'premium'}" (click)="onSubscriptionSelect('premium')">
          <h4 style="float: right">Premium</h4>
          <h4>€<span style="font-weight: bold">649.99</span> per month <span style="font-size: 14px; opacity: .8">incl. 21% VAT</span></h4>
          <p><span style="font-weight: bold">8</span> articles per month</p>
          <p>
            <fa-icon [icon]="fa.faCheck"></fa-icon>
            Access to Statements
          </p>
        </div>
        <div class="option disabled" style="border: 2px solid #f405ff; background: #f405ff"
             [ngClass]="{'selected': company.sub_info.subscription === 'unlimited'}" (click)="onSubscriptionSelect('unlimited')">
          <h4 style="float: right">Unlimited</h4>
          <h4>€<span style="font-weight: bold">899.99</span> per month <span style="font-size: 14px; opacity: .8">incl. 21% VAT</span></h4>
          <p><span style="font-weight: bold">Unlimited</span> articles per month</p>
          <p>
            <fa-icon [icon]="fa.faCheck"></fa-icon>
            Access to Statements
          </p>
        </div>
      </div>
      <div *ngIf="company.sub_info.subscription == 'free'">
        <p class="link" (click)="company.sub_info.subscription = ''" style="position: relative; top: 15px">
          <fa-icon [icon]="fa.faArrowLeft"></fa-icon>
          Select subscription plan
        </p>
        <div class="default">
          <h4 style="font-weight: bold">Free</h4>
          <p>Selecting the Free plan allows you to create articles on Wireplate without having to make financial commitments.</p>
          <small> The Wireplate Free plan will allow you to post a single article per month. It will be possible to select a different plan
            in the future. Organisational data will be transferred, unless a breach of <a [routerLink]="['/terms-of-use']">terms of use</a>
            has been noticed.</small>
          <small><span
            style="font-weight: bold">This includes, but is not limited to, the creation of multiple accounts to circumvent the monthly article limit, or using organisational data like names and images that you do not have the rights to.</span></small>
          <small>Your account and email will be used to create this organisation. You can manage this in your organisational settings.</small>
        </div>
      </div>
      <div *ngIf="company.sub_info.subscription && company.sub_info.subscription !== 'free'">
        <p class="link" (click)="company.sub_info.subscription = ''" style="position: relative; top: 15px">
          <fa-icon [icon]="fa.faArrowLeft"></fa-icon>
          Select subscription plan
        </p>
        <div class="option white" style="border: 2px solid #a0a0a0"
             [ngClass]="{'selected': company.sub_info.billing === 'automatic_monthly', 'hiding': company.sub_info.billing !== '' && company.sub_info.billing !== 'automatic_monthly'}"
             (click)="company.sub_info.billing = 'automatic_monthly'">
          <h4 style="float: right">Monthly</h4>
          <h4 style="font-weight: bold">Automatic</h4>
          <p>Monthly billing through credit card, managed by Stripe.</p>
          <p>First invoice is manual, subsequent invoices are automatic.</p>
        </div>
        <div class="option white" style="border: 2px solid #a0a0a0"
             [ngClass]="{'selected': company.sub_info.billing === 'invoice_monthly', 'hiding': company.sub_info.billing !== '' && company.sub_info.billing !== 'invoice_monthly'}"
             (click)="company.sub_info.billing = 'invoice_monthly'">
          <h4 style="float: right">Monthly</h4>
          <h4 style="font-weight: bold">Manual</h4>
          <p>Manual billing through a digital invoice that is paid monthly.</p>
        </div>
        <div class="option white" style="border: 2px solid #a0a0a0"
             [ngClass]="{'selected': company.sub_info.billing === 'invoice_yearly', 'hiding': company.sub_info.billing !== '' && company.sub_info.billing !== 'invoice_yearly'}"
             (click)="company.sub_info.billing = 'invoice_yearly'">
          <h4 style="float: right">Yearly</h4>
          <h4 style="font-weight: bold">Manual</h4>
          <p>Manual billing through a digital invoice that is paid yearly.</p>
        </div>
        <div class="appear" *ngIf="company.sub_info.billing">
          <div class="group">
            <label for="email">
              <span class="title">Billing email</span>
              <span class="faded">Will receive invoices</span>
            </label>
            <input type="text" id="email" [(ngModel)]="company.sub_info.billing_email" placeholder="Email here...">
          </div>
          <div class="group">
            <label for="phonenumber">
              <span class="title">Phone number</span>
              <span class="faded">Must be same department or person as billing email</span>
            </label>
            <input type="text" id="phonenumber" [(ngModel)]="company.sub_info.billing_phonenumber" placeholder="Phone number here...">
          </div>
          <div class="group" *ngIf="company.sub_info.billing !== 'automatic_monthly'">
            <label for="vatnumber">
              <span class="title">VAT number</span>
              <span class="faded">Will be displayed on the invoice</span>
            </label>
            <input type="text" id="vatnumber" [(ngModel)]="company.sub_info.billing_vatnumber" placeholder="VAT number here...">
          </div>
          <div class="group" *ngIf="company.sub_info.billing == 'automatic_monthly'">
            <small>Monthly automatic billing is managed by Stripe. Invoices do not contain your VAT number. Please note this when you
              require this information on your invoices. Our VAT number is included.</small>
          </div>
          <small *ngIf="company.sub_info.billing === 'invoice_monthly' || company.sub_info.billing === 'invoice_yearly'">An invoice will be
            sent to this address every {{company.sub_info.billing === 'invoice_yearly' ? 'year' : 'month'}}. You need to fulfill your
            first payment before you can post. Subsequent invoices need to be fulfilled within 30 days after the invoice date.</small>
          <small *ngIf="company.sub_info.billing === 'invoice_monthly' || company.sub_info.billing === 'invoice_yearly'"><span
            style="font-weight: bold">You should receive the first invoice within 1 hour after creation. Please also check your spam folder.</span></small>
          <small *ngIf="company.sub_info.billing === 'automatic_monthly'">A first invoice will be sent to this address. Once you fulfill the
            first payment, automatic monthly billing will start. You need to fulfill your first payment before you can post. Subsequent
            invoices need to be fulfilled within 15 days after the invoice date.</small>
        </div>
      </div>
    </div>
    <button class="saver two" [ngClass]="{'active': this.canSave()}" id="saver2" (click)="saveChanges()">
      <span *ngIf="!saving">Create organization</span>
      <fa-icon *ngIf="saving" [icon]="fa.faSyncAlt"></fa-icon>
    </button>
  </div>
</div>
<app-footer></app-footer>
