import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'dateTimeTransform'
})
export class DatetimetransformPipe implements PipeTransform {

  constructor( @Inject( LOCALE_ID ) private locale: string ) { }
  transform(value: string): string {
    const date = new Date(value);
    const datePipe = new DatePipe(this.locale);
    return datePipe.transform(date, 'd MMMM, yyyy \'at\' HH:mm');
  }
}
