<div class="wrapper">
  <h2>Subscription</h2>
  <div *ngIf="subscription === 'ManagedByWireplate'">
    <h1>Your subscription is managed by Wireplate.</h1>
  </div>
  <div *ngIf="subscription && subscription.plan === 'Free'">
    <h4>Current subscription</h4>
    <div *ngIf="subscription">
      <div class="item inactive">
        <div class="info">
          <h5 style="font-weight: bold">Free</h5>
          <p>Active since {{subscription.created | utcToDate}}</p>
          <div style="font-weight: bold">
            Remaining articles this month: {{subscription.allowed || 0}}
          </div>
        </div>
        <div class="status" [ngClass]="'active'">Active</div>
      </div>
    </div>
  </div>
  <div *ngIf="subscription && subscription !== 'ManagedByWireplate' && subscription.plan !== 'Free'">
    <h4>Current subscription</h4>
    <div *ngIf="subscription">
      <div class="item inactive">
        <div class="info">
          <h5 style="font-weight: bold">{{subscription.plan | capitalizeFirstLetter}}</h5>
          <p *ngIf="!subscription.external">Active since {{subscription.created | utcToDate}}</p>
          <p *ngIf="!subscription.external">Current period from {{subscription.current_period_start | utcToDate}} to {{subscription.current_period_end | utcToDate}}</p>
          <p *ngIf="subscription.external">Active since {{subscription.created | unixToDate}}</p>
          <p *ngIf="subscription.external">Current period from {{subscription.current_period_start | unixToDate}} to {{subscription.current_period_end | unixToDate}}</p>
        </div>
        <div class="status" [ngClass]="subscription.status">{{subscription.status | capitalizeFirstLetter | replaceUnderscoreWithSpace}}</div>
      </div>
    </div>
    <div *ngIf="!subscription && !loadingSub">
      You have not yet subscribed to a plan
    </div>
    <h4>Billing history</h4>
    <p *ngIf="!subscription.external">Your invoices are not shown here yet.</p>
    <div *ngFor="let item of history">
      <div class="item" *ngIf="item.status === 'draft'">
        <div class="info">
          <p style="font-weight: bold">{{item.created | unixToDate}} - {{item.number}}</p>
          <p>{{item.period_start | unixToDate}} to {{item.period_end | unixToDate}}</p>
          <p>{{item.currency | currencySymbol | uppercase}}{{item.total | amountNumberPipe}}
            <span style="opacity: .75;">incl. 21% VAT</span>
          </p>
          <p style="font-weight: bold">Invoice is being prepared. This can take up to an hour. Please come back later.</p>
        </div>
        <div class="status" [ngClass]="item.status">{{item.status | capitalizeFirstLetter}}</div>
      </div>
      <a [href]="item.hosted_invoice_url" target="_blank" *ngIf="item.status !== 'draft'">
        <div class="item">
          <div class="info">
            <p style="font-weight: bold">{{item.created | unixToDate}} - {{item.number}}</p>
            <p>{{item.period_start | unixToDate}} to {{item.period_end | unixToDate}}</p>
            <p>{{item.currency | currencySymbol | uppercase}}{{item.total | amountNumberPipe}}
              <span style="opacity: .75;">incl. 21% VAT</span>
            </p>
          </div>
          <div class="status" [ngClass]="item.status">{{item.status | capitalizeFirstLetter}}</div>
        </div>
      </a>
    </div>


    <div *ngIf="!history && !loading">
      You have not been billed yet, and so no billing history can be shown
    </div>
  </div>
</div>
