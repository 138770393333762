import {Component, OnDestroy, OnInit} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {EnvService} from '../../services/env.service';
import {AuthService} from '../../auth/auth.service';
import {AlertService} from '../../services/alert.service';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent implements OnInit, OnDestroy {
  public fa = icons;
  public sub;
  public id;
  public company;
  public latestArticles = [];
  public latestCursor;
  public isLoadingLatest = false;
  public canEdit = false;
  public isLoadingSubscribed = false;
  public isSubscribed = null;

  public latestStatements = [];
  public latestStatementsCursor;
  public isLoadingLatestStatements = false;

  constructor(private titleService: Title, private route: ActivatedRoute, private alertService: AlertService, public router: Router,
              private dataService: DataService, private envService: EnvService, private authService: AuthService,
              public ls: LanguageService) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id.split('-')[0];

      if (isNaN(this.id)) {
        this.dataService.getCompanyWithIdentifier(this.id).subscribe(response => {
          this.company = response;

          this.id = this.company.id;
          this.load();
        }, error => {
          this.alertService.alert(
            {
              content: `We could not find this organization on Wireplate`,
              title: 'Not found',
              type: 'warning'
            });
        });
      } else {
        this.dataService.getCompany(this.id).subscribe(response => {
          this.company = response;
          this.load();
        }, error => {
          this.alertService.alert(
            {
              content: `We could not find this organization on Wireplate`,
              title: 'Not found',
              type: 'warning'
            });
        });
      }

      this.titleService.setTitle(`${params.id.split('-')[1] || 'Organisation'} - Wireplate`);
    });
  }

  load() {
    this.isLoadingSubscribed = true;
    this.dataService.getSubscriptionByEntityId(this.id).subscribe(r => {
      // tslint:disable-next-line:triple-equals
      this.isSubscribed = r.sub_entity == this.id;
      this.isLoadingSubscribed = false;
    }, error => {
      this.isSubscribed = false;
      this.isLoadingSubscribed = false;
    });

    this.authService.changeReadyAuth.subscribe(auth => {
      this.route.params.subscribe(params => {
        // tslint:disable-next-line:triple-equals
        this.canEdit = auth && auth.filter(e => e.company == this.id).length > 0;
      });
    });

    this.loadLatestArticles();
    this.loadLatestStatements();
  }

  subUnsub() {
    if (this.isSubscribed === null) {
      return;
    }

    if (!this.authService.isLoggedIn) {
      this.router.navigate(['auth']);
    }

    this.isLoadingSubscribed = true;

    if (this.isSubscribed) {
      this.dataService.deleteSubscriptionByEntityId(this.id).subscribe(response => {
        this.alertService.alert(
          {
            content: `You are no longer following ${this.company.name}`,
            title: 'Unfollow',
            type: 'success'
          });
        this.isSubscribed = false;
        this.isLoadingSubscribed = false;
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}: Could not unfollow ${this.company.name}`,
            title: 'Something went wrong',
            type: 'error'
          });
      });
    } else if (!this.isSubscribed) {
      this.dataService.postSubscriptionWithType(this.id, 1).subscribe(response => {
        this.alertService.alert(
          {
            content: `You are following ${this.company.name}`,
            title: 'Following',
            type: 'success'
          });
        this.isSubscribed = true;
        this.isLoadingSubscribed = false;
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}: Could not follow ${this.company.name}`,
            title: 'Something went wrong',
            type: 'error'
          });
      });
    }
  }

  articleLink(id, title) {
    return id + '-' + title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  }

  getImageURL() {
    return this.envService.imageUrl + '/companies/' + this.id + '/logos/250x250';
  }

  loadLatestArticles() {
    if (!this.isLoadingLatest) {
      this.isLoadingLatest = true;
      this.dataService.getCompanyLatestArticles(this.id, 6, this.latestCursor).subscribe(response => {
        this.latestArticles.push(...response.articles);
        this.latestCursor = response.cursor;
        this.isLoadingLatest = false;
      });
    }
  }

  loadLatestStatements() {
    if (!this.isLoadingLatestStatements) {
      this.isLoadingLatestStatements = true;
      this.dataService.getCompanyLatestStatements(this.id, 6, this.latestStatementsCursor).subscribe(response => {
        this.latestStatements.push(...response.statements);
        this.latestStatementsCursor = response.cursor;
        this.isLoadingLatestStatements = false;
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
