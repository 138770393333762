import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingComponent} from './modules/landing/landing.component';
import {ArticleComponent} from './modules/article/article.component';
import {EditComponent} from './modules/edit/edit.component';
import {OrganisationComponent} from './modules/organisation/organisation.component';
import {AuthComponent} from './components/auth/auth.component';
import {ProfileSettingsComponent} from './modules/profile-settings/profile-settings.component';
import {ProfSetTabGeneralComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-general/prof-set-tab-general.component';
import {ProfSetTabOrganisationsComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-organisations/prof-set-tab-organisations.component';
import {AuthGuard} from './auth/auth.guard';
import {LandingYouComponent} from './modules/landing/pages/landing-you/landing-you.component';
import {LandingLatestComponent} from './modules/landing/pages/landing-latest/landing-latest.component';
import {LandingFrontComponent} from './modules/landing/pages/landing-front/landing-front.component';
import {OrganisationSettingsComponent} from './modules/organisation-settings/organisation-settings.component';
import {OrgSetTabGeneralComponent} from './modules/organisation-settings/organisation-settings-tabs/org-set-tab-general/org-set-tab-general.component';
import {OrgSetTabRolesComponent} from './modules/organisation-settings/organisation-settings-tabs/org-set-tab-roles/org-set-tab-roles.component';
import {OrgSetTabBillingComponent} from './modules/organisation-settings/organisation-settings-tabs/org-set-tab-billing/org-set-tab-billing.component';
import {SignupComponent} from './components/signup/signup.component';
import {ArticleResolver} from './resolvers/article.resolver';
import {ProfSetTabInfoComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-info/prof-set-tab-info.component';
import {ProfSetTabSubscriptionsComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-subscriptions/prof-set-tab-subscriptions.component';
import {TopicComponent} from './modules/topic/topic.component';
import {LandingFeedComponent} from './modules/landing/pages/landing-feed/landing-feed.component';
import {ProfSetTabHistoryComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-history/prof-set-tab-history.component';
import {ArticleAnalyticsComponent} from './modules/article-analytics/article-analytics.component';
import {PrivacyPolicy} from './singles/privacy-policy';
import {CookiePolicy} from './singles/cookie-policy';
import {TermsOfUse} from './singles/terms-of-use';
import {Contact} from './singles/contact';
import {StatementHolderComponent} from './modules/statement-holder/statement-holder.component';
import {StatementEditComponent} from './modules/statement-edit/statement-edit.component';
import {NewOrganisationComponent} from './modules/new-organisation/new-organisation.component';
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';
import {SearchComponent} from './modules/search/search.component';
import {AiSearchComponent} from './modules/ai-search/ai-search.component';
import {AboutComponent} from './modules/about/about.component';
import {StatementResolver} from './resolvers/statement.resolver';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {path: '', component: LandingFrontComponent},
      {path: 'feed', component: LandingFeedComponent, canActivate: [AuthGuard]},
      {path: 'you', component: LandingYouComponent, canActivate: [AuthGuard]},
      {path: 'latest', component: LandingLatestComponent}
    ]
  },
  {path: 'auth', component: AuthComponent},
  {path: 'auth/signup', component: SignupComponent},
  {path: 'auth/verify-email', component: VerifyEmailComponent},
  {path: 'statement', component: StatementEditComponent, canActivate: [AuthGuard]},
  {path: 'statement/:id', component: StatementHolderComponent, resolve: {statement: StatementResolver}, data: {ssr: true}},
  {path: 'article/:id', component: ArticleComponent, resolve: {article: ArticleResolver}, data: {ssr: true}},
  {path: 'article/:id/analytics', component: ArticleAnalyticsComponent, canActivate: [AuthGuard]},
  {
    path: 'account', component: ProfileSettingsComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      {
        path: '',
        redirectTo: 'general',
        pathMatch: 'full'
      },
      {path: 'general', component: ProfSetTabGeneralComponent},
      {path: 'following', component: ProfSetTabSubscriptionsComponent},
      {path: 'info', component: ProfSetTabInfoComponent},
      {path: 'organisations', component: ProfSetTabOrganisationsComponent},
      {path: 'history', component: ProfSetTabHistoryComponent}
    ]
  },
  {path: 'topic/:id', component: TopicComponent},
  {
    path: 'org/new', component: NewOrganisationComponent, canActivate: [AuthGuard]
  },
  {
    path: 'org/:id', component: OrganisationComponent
  },
  {
    path: 'org/:id/settings', component: OrganisationSettingsComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      {
        path: '',
        redirectTo: 'general',
        pathMatch: 'full'
      },
      {path: 'general', component: OrgSetTabGeneralComponent},
      {path: 'roles', component: OrgSetTabRolesComponent},
      {path: 'subscription', component: OrgSetTabBillingComponent},
    ]
  },
  {path: 'edit', component: EditComponent, canActivate: [AuthGuard]},
  {path: 'edit/:id', component: EditComponent, canActivate: [AuthGuard]},
  {path: 'privacy-policy', component: PrivacyPolicy},
  {path: 'cookie-policy', component: CookiePolicy},
  {path: 'terms-of-use', component: TermsOfUse},
  {path: 'contact', component: Contact},
  {path: 'search', component: SearchComponent},
  {path: 'ai-search', component: AiSearchComponent},
  {path: 'about', component: AboutComponent},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  providers: [ArticleResolver, StatementResolver],
  exports: [RouterModule]
})
// @ts-ignore
export class AppRoutingModule {
}

// @ts-ignore
