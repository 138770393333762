<div class="wrapper">
  <div class="frame">
    <h5 style="text-decoration: none!important;" *ngIf="!deleteStatementText">Statement</h5>
    <a [routerLink]="['/org', companyId]" *ngIf="companyId && companyName">
      <p>{{companyName}}
        <fa-icon [icon]="fa.faCheckCircle" *ngIf="companyName !== '-'" delayLoad></fa-icon>
      </p>
    </a>
    <p *ngIf="!companyId && companyName">{{companyName}}
      <fa-icon [icon]="fa.faCheckCircle"></fa-icon>
    </p>
    <p [title]="date | utcToClientTimezone">{{date | customDateTime}}</p>
    <h5 class="title" *ngIf="!isHeld">{{title}}</h5>
    <h2 class="title" *ngIf="isHeld">{{title}}</h2>
  </div>
</div>
