import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  HostListener,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AlertService} from './services/alert.service';
import {AlertComponent} from './components/alert/alert.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('alertContainer', {read: ViewContainerRef}) container;
  public networkMessage = '';

  constructor(private alertService: AlertService, private resolver: ComponentFactoryResolver) {
    this.alertService.alert$
      .pipe()
      .pipe()
      .subscribe((alert) => {
        if (alert.content !== '') {
          this.createAlertComponent(alert);
        }
      });
  }


  createAlertComponent(alert) {
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(AlertComponent);
    const componentRef: ComponentRef<any> = this.container.createComponent(factory);
    componentRef.instance.alert = alert;
    setTimeout(() => {
      componentRef.destroy();
    }, 8000);
  }

  @HostListener('window:offline', ['$event'])
  offline() {
    this.networkMessage = 'offline';
  }

  @HostListener('window:online', ['$event'])
  online() {
    this.networkMessage = 'online';
    setTimeout(() => {
      this.networkMessage = '';
    }, 5000);
  }
}
