import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';

@Pipe({
  name: 'utcToClientTimezone'
})
export class UtcToClientTimezonePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: string, format: string = 'medium'): string {
    const date = new Date(value);
    return date.toString();
  }

}
