<div class="wrapper">
  <div class="profset">
    <div class="information">
      <h1>Account</h1>
      <h5 *ngIf="authService.userData">Account information for <span class="name">{{authService.userData.displayName}}</span></h5>
      <h5 *ngIf="!authService.userData">Account information</h5>
    </div>
    <div class="row">
      <div class="col-md-4 col-lg-4 col-xl-4 navigation">
        <a [routerLink]="['general']"><div class="navigation-item"
             [routerLinkActive]="['is-active']">
          General
        </div></a>
        <a [routerLink]="['following']"><div class="navigation-item"
                                        [routerLinkActive]="['is-active']">
          Following
        </div></a>
<!--        <a [routerLink]="['saved']"><div class="navigation-item"-->
<!--                                             [routerLinkActive]="['is-active']">-->
<!--          Saved-->
<!--        </div></a>-->
<!--        <a [routerLink]="['info']"><div class="navigation-item"-->
<!--                                                 [routerLinkActive]="['is-active']">-->
<!--          Work Profile-->
<!--        </div></a>-->
        <a [routerLink]="['organisations']"><div class="navigation-item"
             [routerLinkActive]="['is-active']">
          Organisations
        </div></a>
        <hr>
        <a [routerLink]="['history']"><div class="navigation-item important"
                                                 [routerLinkActive]="['is-active']">
          History
        </div></a>
        <a href="mailto:support@wireplate.com" target="_blank"><div class="navigation-item important"
             [routerLinkActive]="['is-active']">
          Request data <fa-icon [icon]="far.faEnvelope"></fa-icon>
        </div></a>
        <a href="https://github.com/Inphent/wireplate-support" target="_blank"><div class="navigation-item important"
                                                                                    [routerLinkActive]="['is-active']">
          Support & Help <fa-icon [icon]="fa.faExternalLinkAlt"></fa-icon>
        </div></a>
      </div>
      <div class="col-md-8 col-lg-8 col-xl-8 content" *ngIf="authService.userData">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
