import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  template: `
    <div class="container">
      <h1>Contact</h1>
      <p>If you have any questions, concerns, or requests, please contact us at:</p>

      <a target="_blank" href="https://github.com/Inphent/wireplate-support">Wireplate Support on Github</a>
      <br>
      <br>
      <p>We will make reasonable efforts to address your inquiries and resolve any concerns.</p>
    </div>
  <app-footer></app-footer>`,
})
export class Contact {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Contact- Wireplate');
  }


}
