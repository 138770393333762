import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../auth/auth.service';
import {AlertService} from '../../../../services/alert.service';
import {DataService} from '../../../../services/data.service';

enum SubscriptionType {
  Topic,
  Company
}

@Component({
  selector: 'app-prof-set-tab-subscriptions',
  templateUrl: './prof-set-tab-subscriptions.component.html',
  styleUrls: ['./prof-set-tab-subscriptions.component.scss']
})
export class ProfSetTabSubscriptionsComponent implements OnInit {

  public user;
  public subscriptions;
  public loadingCycles = {};
  constructor(public authService: AuthService, private alertService: AlertService, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.authService.changeReady.subscribe(message => {
      if (message) {
        this.user = {email: message.email, displayName: message.displayName};
        this.dataService.getSubscriptionForUser().subscribe(response => {
          this.subscriptions = response;
          for (const x of this.subscriptions) {
           this.loadingCycles[x.sub_entity] = true;
           if (x.type) {
             this.dataService.getCompany(x.sub_entity).subscribe(r2 => {
               this.loadingCycles[x.sub_entity] = false;
               x.info = r2;
             });
           } else {
             this.dataService.getTopic(x.sub_entity).subscribe(r2 => {
               this.loadingCycles[x.sub_entity] = false;
               x.info = r2;
             });
           }
          }
        }, error => {
          this.alertService.alert(
            {
              content: `${error.error.detail || error.error}: Could not load subscriptions`,
              title: 'Something went wrong',
              type: 'error'
            });
        });
      }
    });
  }

  getSubType(type) {
    return SubscriptionType[type];
  }


}
