import { Directive, ElementRef, HostListener, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appPlaceholder]'
})
export class PlaceholderDirective implements AfterViewInit {
  private placeholderText = 'Type something here...';

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    // Add the placeholder text when the div is empty
    if (this.el.nativeElement.textContent.trim() === '') {
      this.el.nativeElement.textContent = this.placeholderText;
    }
  }

  @HostListener('keydown')
  onKeyDown() {
    // Remove the placeholder text when the user starts typing
    if (this.el.nativeElement.textContent.trim() === this.placeholderText) {
      this.el.nativeElement.textContent = '';
    }
  }

  @HostListener('blur')
  onBlur() {
    // Add the placeholder text when the div is empty again
    if (this.el.nativeElement.textContent.trim() === '') {
      this.el.nativeElement.textContent = this.placeholderText;
    }
  }

  @HostListener('focus')
  onFocus() {
    // Remove the placeholder text when the user selects the div
    if (this.el.nativeElement.textContent.trim() === this.placeholderText) {
      this.el.nativeElement.textContent = '';
    }
  }
}
