import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  template: `
    <div class="container">
      <h1>Cookie Policy for Wireplate</h1>

      <p>This Cookie Policy explains how Wireplate ("we," "us," or "our") uses cookies and similar tracking technologies on our website to collect and store certain information. By using Wireplate, you consent to the use of cookies as described in this policy.</p>

      <h3>1. What Are Cookies?</h3>

      <p>Cookies are small text files that are stored on your device when you visit a website. They serve various purposes, including remembering your preferences, analyzing website usage, and providing personalized content.</p>

      <h3>2. Types of Cookies We Use</h3>

      <p>2.1 Necessary Cookies:</p>
      <p>These cookies are essential for the functioning of Wireplate and cannot be disabled in our systems. They enable basic features such as user authentication, account management, and security.</p>

      <p>2.2 Analytics Cookies:</p>
      <p>We use analytics cookies to gather information about how visitors use Wireplate, such as which pages are visited, how long visitors stay on the site, and how they arrived here. This data helps us analyze and improve the performance and user experience of our website.</p>

      <p>2.3 Functional Cookies:</p>
      <p>Functional cookies allow Wireplate to remember your preferences and provide enhanced functionality. For example, they may remember your language preference or the region you are in.</p>

      <p>2.4 Marketing and Advertising Cookies:</p>
      <p>We may use marketing and advertising cookies to deliver personalized advertisements and promotional content based on your interests and browsing behavior on Wireplate and other websites. These cookies may track your activities across different websites.</p>

      <h3>3. Your Cookie Choices</h3>

      <p>3.1 Consent:</p>
      <p>By continuing to use Wireplate, you consent to the use of cookies as described in this Cookie Policy. You can manage your cookie preferences through your browser settings or by using the cookie consent banner that appears on our website.</p>

      <p>3.2 Browser Settings:</p>
      <p>You can usually configure your browser to accept or reject cookies, or to prompt you before accepting cookies. Please note that blocking or deleting cookies may impact the functionality and user experience of Wireplate.</p>

      <p>3.3 Opt-Out:</p>
      <p>You can opt-out of certain types of cookies, such as marketing and advertising cookies, by visiting the opt-out pages provided by relevant third-party service providers. For more information, please refer to our <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.</p>

      <h3>4. Data Retention</h3>

      <p>We retain cookie-related information for the period necessary to fulfill the purposes outlined in this Cookie Policy unless a longer retention period is required or permitted by law.</p>

      <h3>5. Changes to this Cookie Policy</h3>

      <p>We may update this Cookie Policy from time to time. Any changes will be posted on this page with a revised "Effective Date." We encourage you to review this Cookie Policy periodically to stay informed about our use of cookies.</p>

      <h3>6. Contact Us</h3>

      <p>If you have any questions, concerns, or requests regarding this Cookie Policy, please contact us at:</p>

      <a target="_blank" href="https://github.com/Inphent/wireplate-support">Wireplate Support on Github</a>
      <br>
      <br>
      <p>We will make reasonable efforts to address your inquiries and resolve any concerns.</p>
    </div>
  <app-footer></app-footer>`,
})
export class CookiePolicy {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Cookie policy - Wireplate');
  }


}
