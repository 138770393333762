import { Component } from '@angular/core';
import {DataService} from '../../services/data.service';
import {AlertService} from '../../services/alert.service';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-statement-edit',
  templateUrl: './statement-edit.component.html',
  styleUrls: ['./statement-edit.component.scss']
})
export class StatementEditComponent {
  public fa = icons;
  public statement;
  public saving = false;
  public company;

  constructor(private dataService: DataService, private alertService: AlertService, private router: Router) {
  }

  normalize(text) {
    return text.normalize('NFKC');
  }

  save() {
    if (this.statement.length <= 250 && this.statement.length > 0 && this.company) {
      this.saving = true;
      this.dataService.postStatement(this.company, {title: this.statement}).subscribe(response => {
        this.alertService.alert(
          {
            content: `The statement has been published`,
            title: 'Published',
            type: 'info'
          });
        this.saving = false;
        this.router.navigate([`statement/${response}`]);
      }, error => {
        if (error.error === 'Your subscription does not allow for statements to be posted') {
          this.alertService.alert(
            {
              content: `${error.error}`,
              title: 'Subscription limitation',
              type: 'warning'
            });
        } else {
          this.alertService.alert(
            {
              content: `The statement could not be published`,
              title: 'Something went wrong',
              type: 'error'
            });
        }
        this.saving = false;
      });
    }
  }
}
