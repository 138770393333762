import {Component, HostListener} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {DataService} from '../../../../services/data.service';
import {AlertService} from '../../../../services/alert.service';

@Component({
  selector: 'app-landing-you',
  templateUrl: './landing-you.component.html',
  styleUrls: ['./landing-you.component.scss']
})
export class LandingYouComponent {
  public fa = icons;
  public results;
  public isLoadingNew = false;
  public companies = {};

  constructor(private dataService: DataService, private alertService: AlertService) {
    // this.loadResults();
  }

  loadResults() {
    if (!this.isLoadingNew) {
      this.isLoadingNew = true;
      this.dataService.getRecommendations().subscribe(response => {
        this.results = response;
        Array.from(new Set(response.map(obj => obj.company))).forEach(company => {
          // @ts-ignore
          if (!this.companies[company]) {
            this.dataService.getCompany(company).subscribe(cRe => {
              this.companies[cRe.id] = cRe;
            });
          }
        });
        this.isLoadingNew = false;
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}: Could not load articles`,
            title: 'Something went wrong',
            type: 'error'
          });
      });
    }
  }

  getCompanyName(id) {
    if (this.companies && this.companies[id]) {
      return this.companies[id].name;
    }
    return '-';
  }

  articleLink(id, title) {
    return id + '-' + title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  }
}
