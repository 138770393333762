<div class="wrapper">
  <div class="frame">
    <small>Sponsored</small>
    <div class="row">
      <div class="col col-8">
        <h1>Enterprise level business management, for free.</h1>
        <a href="https://uuple.com/">Try Uuple for free today</a>
      </div>
      <div class="col col-4">
        <img src="https://uuple.com/assets/images/Uuple%20u%20logo.png">
      </div>
    </div>
  </div>
</div>
