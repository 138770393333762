<div class="wrapper">
  <h2>Roles</h2>
  <div class="organisations" *ngIf="loading">Loading permissions...</div>
  <div class="add" *ngIf="!loading">
    <input placeholder="Find by email and add..." [(ngModel)]="newUserUID">
    <button (click)="newPermission()">Add</button>
  </div>
  <div class="organisations" *ngIf="!loading">
<!--    <div class="row explain">-->
<!--      <div class="col-9">-->
<!--        User-->
<!--      </div>-->
<!--      <div class="col-2">-->
<!--        Role-->
<!--      </div>-->
<!--      <div class="col-2">-->
<!--      </div>-->
<!--    </div>-->
    <div class="row organisation" *ngFor="let permission of permissions">
      <div class="col-9 info">
        <div>{{permission.user.name}}</div>
        <div class="under">{{permission.user.email}}</div>
      </div>
      <div class="col-2">
        <select [(ngModel)]="permission.permission" (change)="changePermission(permission.user.id, $event)" [disabled]="permission.permission === 'Owner'">
          <option *ngFor="let u of ['Owner', 'Admin', 'Editor']" [value]="u">{{ u }}</option>
        </select>
      </div>
      <div class="col-1 delete">
        <fa-icon [icon]="fa.faTimesCircle" (click)="deletePermission(permission.user.id)" [hidden]="permission.permission === 'Owner'"></fa-icon>
      </div>
    </div>
  </div>
</div>
