<div class="container">
  <div class="head">
    <app-topic-selector [selectedTopic]="topicId" (valueEvent)="selectedTopic($event)"></app-topic-selector>
    <button class="follow" [ngClass]="{'active': isSubscribed}" *ngIf="isSubscribed !== null"
            (click)="subUnsub()">
      <span *ngIf="!isLoadingSubscribed">{{isSubscribed ? 'Following' : 'Follow'}}</span>
      <fa-icon [icon]="fa.faSyncAlt" class="loading" *ngIf="isLoadingSubscribed"></fa-icon>
    </button>
  </div>
  <div class="articles" *ngIf="latestArticles">
    <div class="row">
      <ng-container *ngFor="let article of latestArticles">
        <div class="col-md-3">
          <a [routerLink]="['/article', articleLink(article.id, article.title)]" class="d-block text-decoration-none text-dark">
            <app-frames-image [title]="article.title" [date]="article.created" [id]="article.id"
                              [companyName]="getCompanyName(article.company)" [version]="article.version"></app-frames-image>
          </a>
        </div>
      </ng-container>
    </div>
    <div class="row" *ngIf="isLoadingLatest">
      <div class="col col-3" *ngFor="let num of [0, 1, 2, 3]">
        <div class="skel-loading">
        </div>
      </div>
    </div>
    <div class="extend" (click)="loadLatestArticles()"
         *ngIf="(latestCursor || latestCursor === undefined) && latestArticles.length !== 0">
      <p *ngIf="!isLoadingLatest">Load more articles</p>
      <fa-icon [icon]="fa.faSyncAlt" *ngIf="isLoadingLatest"></fa-icon>
    </div>
  </div>
</div>
<app-footer></app-footer>
