<div class="bod">
  <div class="wrapper" id="login">
    <div class="login">
      <img src="assets/images/dots.png" class="login-image" draggable="false">
      <div class="form-uuple" *ngIf="localLogin">
        <fa-icon class="login-control" [icon]="fa.faArrowLeft" (click)="localLogin = false"></fa-icon>
        <div class="login-username">
          <form [formGroup]="localFormGroup">
            <div class="login-username-input">
              <input type="text"
                     name="email"
                     disabled="disabled"
                     class="readonly"
                     readonly
                     formControlName="email">
            </div>
            <div class="login-username-input">
              <input type="password"
                     autocomplete="on"
                     [ngClass]="{'alerting': localFormGroup.controls['password'].invalid && localFormGroup.controls['password'].touched}"
                     name="password"
                     autofocus
                     (keydown.enter)="login()"
                     formControlName="password">
            </div>
            <span class="link" (click)="forgotPassword()">Forgot password</span>
            <div class="login-username-btn" (click)="login()">
              <button>Log in</button>
            </div>
          </form>
        </div>
      </div>
      <div class="form-initial" *ngIf="!localLogin">
        <div class="login-username">
          <form [formGroup]="loginFormGroup">
            <div class="login-username-input">
              <input type="text"
                     placeholder="Your email"
                     autocomplete="on"
                     [ngClass]="{'alerting': loginFormGroup.controls['email'].invalid && loginFormGroup.controls['email'].touched}"
                     name="username"
                     (keydown.enter)="email()"
                     formControlName="email">
            </div>
            <div class="login-username-btn" (click)="email()">
              <button>Continue</button>
            </div>
          </form>
        </div>
        OR
        <div class="break"></div>
        <div class="login-buttons">
          <div class="login-buttons-btn blocked" id="loginGoogle">
            <span class="login-buttons-btn-img">
                <img src="assets/images/google.png">
            </span>
            Continue with Google
          </div>
          <div class="login-buttons-btn blocked">
            <span class="login-buttons-btn-img">
                <img src="assets/images/microsoft.png">
            </span>
            Continue with Microsoft
          </div>
        </div>
      </div>
      <div class="login-notice">
        After logging in, you will be redirected to<br>
        <div class="login-notice-url">{{to.display}}</div>
      </div>
    </div>
    <div class="policies">
      <a [routerLink]="['/privacy-policy']">Privacy Policy</a> • <a [routerLink]="['/terms-of-use']">Terms of Use</a>
    </div>
  </div>
  <div class="products">
    <span>Solutions with strategy.</span>
    KVK 88169227 <a href="https://inphent.com/" target="_blank">Inphent</a>. All rights reserved.
  </div>
</div>
