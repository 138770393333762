<div class="container">
  <app-frames-statement [isHeld]="true" [date]="statement.created" [companyName]="company ? company.name : '-'" [title]="statement.title" *ngIf="statement" [companyId]="statement.company"></app-frames-statement>
  <div class="deck" *ngIf="canEdit">
    <p><span>Statement Options</span> <small style="float: right">Only you can see this</small></p>
    <span class="control" style="text-decoration: none!important;">Statements are not editable</span>
    <span class="control" style="float: right; color: #ff4545; font-weight: bold" *ngIf="!isDeleting" (click)="isDeleting = true"><span>Delete</span></span>
    <span class="control" style="float: right; text-decoration: none; font-weight: bold;" *ngIf="isDeleting">
          <span style="margin-right: 10px; color: #ff4545; text-decoration: underline #ff4545;" (click)="deleteStatement()">Yes, delete</span>
          <span style="text-decoration: underline;" (click)="isDeleting = false">No, keep</span>
        </span>
  </div>
</div>
<app-footer></app-footer>
