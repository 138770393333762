import { Component } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  public fa = icons;
  public today;
  constructor() {
    this.today = new Date().toISOString();
  }

}
