import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'urlAnchor'
})
export class UrlAnchorPipe implements PipeTransform {
  transform(value: string): string {
    const urlRegex = /(?:https?:\/\/)?(?:www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}(?:\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?)/gi;

    return value.replace(urlRegex, (match, url) => {
      const fullURL = match.startsWith('https') || match.startsWith('http') ? match : `https://${match}`;
      return `<a class="styled-anchor" target="_blank" href="${fullURL}">${match}</a>`;
    });
  }

}
