import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-organisation-settings',
  templateUrl: './organisation-settings.component.html',
  styleUrls: ['./organisation-settings.component.scss']
})
export class OrganisationSettingsComponent implements OnInit {

  public user = null;
  public fa = icons;

  constructor(private titleService: Title, private authService: AuthService,
              private route: ActivatedRoute, private alertService: AlertService, private router: Router) {
    this.titleService.setTitle('Organisation settings - Wireplate');
  }

  ngOnInit(): void {
    this.authService.changeReadyAuth.subscribe(auth => {
      this.route.params.subscribe(params => {
        if (auth && auth.filter(e => e.company === +params.id).length === 0) {
          this.router.navigate(['']);
          this.alertService.alert(
            {
              content: `You are not allowed here`,
              title: 'Not allowed',
              type: 'warning'
            });
        }
      });
    });
  }

}
