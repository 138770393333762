<div class="container">
  <div class="selector">
    <div class="item" [ngClass]="{'selected': selected === 'articles'}" (click)="selected = 'articles'; results = null; searchString = null">
      Articles
    </div>
    <div class="item" [ngClass]="{'selected': selected === 'companies'}" (click)="selected = 'companies'; results = null; searchString = null">
      Companies
    </div>
    <div class="item" [ngClass]="{'selected': selected === 'topics'}" (click)="selected = 'topics'; results = null; searchString = null">
      Topics
    </div>
  </div>
  <div class="row input">
    <input type="text" (keyup.enter)="loadResults()"
           [(ngModel)]="searchString"
           placeholder="Search Wireplate...">
    <fa-icon [icon]="fa.faArrowRight" (click)="loadResults()"></fa-icon>
  </div>
  <div class="row">
<!--    <div class="col-md-2">-->
<!--      <div class="options">-->
<!--        <span style="font-weight: bold">Options</span>-->
<!--        <br>-->
<!--        There should be options here...-->
<!--      </div>-->
<!--    </div>-->
    <div class="results">
      <span *ngIf="(!results || results.length === 0) && !isLoadingNew">No results found</span>
      <div class="end" *ngIf="isLoadingNew">
        <fa-icon [icon]="fa.faSyncAlt"></fa-icon>
      </div>
      <div class="cards" *ngIf="results && !isLoadingNew && selected === 'articles'">
        <a *ngFor="let item of results" [routerLink]="['/article', articleLink(item.id, item.title)]">
          <app-frames-card [title]="item.title" [date]="item.created" [id]="item.id"
                           [companyName]="getCompanyName(item.company)" [version]="item.version"></app-frames-card>
          <app-frames-image [title]="item.title" [date]="item.created" [id]="item.id" [version]="item.version" [companyName]="getCompanyName(item.company)"></app-frames-image>
        </a>
      </div>
      <div class="cards" *ngIf="results && !isLoadingNew && selected === 'companies'">
        <a *ngFor="let item of results" [routerLink]="['/org', item.id]">
          <h3>{{item.name}}</h3>
          <h5>{{item.identifier}}</h5>
          <br>
        </a>
      </div>
      <div class="cards" *ngIf="results && !isLoadingNew && selected === 'topics'">
        <a *ngFor="let item of results" [routerLink]="['/topic', item.id]">
          <h3>{{item.name}}</h3>
          <br>
        </a>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
