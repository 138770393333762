<div class="wrapper" (click)="loading = true">
  <div class="frame">
    <div class="set" [ngClass]="{'loading': loading}" [ngStyle]="{'background-image': 'url(' + mainImage + ')'}">
      <fa-icon [icon]="fa.faSyncAlt" *ngIf="loading"></fa-icon>
    </div>
    <div class="info">
      <h4>{{title}}</h4>
      <p>{{companyName || ''}}<fa-icon [icon]="fa.faCheckCircle" *ngIf="companyName"></fa-icon> <span [title]="date | utcToClientTimezone">{{date | customDateTime}}</span></p>
    </div>
  </div>
</div>
