<div class="wrapper">
  <div class="login">
    <img src="assets/images/dots.png" class="login-image" draggable="false">
    <div class="form-initial">
      <div class="login-username" *ngIf="!verifiedSent">
        <p>Your email has not been verified yet</p>
          <div class="login-username-btn" (click)="sendEmail()">
            <button>Send verification email</button>
          </div>
      </div>
      <div class="login-username" *ngIf="verifiedSent">
        <p>Please see your inbox (and spam folder)</p>
        <div class="login-username-btn" (click)="continue()">
          <button>Continue</button>
        </div>
      </div>
    </div>
    <div class="login-notice">
      After verifying, you will be redirected to<br>
      <div class="login-notice-url" id="noticeUrl">wireplate.com</div>
    </div>
  </div>
  <div class="policies">
    <a [routerLink]="['/privacy-policy']">Privacy Policy</a> • <a [routerLink]="['/terms-of-use']">Terms of Use</a>
  </div>
</div>
<div class="products">
  <span>Solutions with strategy.</span>
  KVK 88169227 <a href="https://inphent.com/" target="_blank">Inphent</a>. All rights reserved.
</div>
