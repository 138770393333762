import {Component, HostListener} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-landing-latest',
  templateUrl: './landing-latest.component.html',
  styleUrls: ['./landing-latest.component.scss']
})
export class LandingLatestComponent {
  public fa = icons;
  public cursor;
  public articles = [];
  public isLoadingNew = false;
  public companies = {};
  constructor(private dataService: DataService) {
    this.loadArticles();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.isLoadingNew &&
      (this.cursor || this.cursor === undefined) && this.articles.length !== 0) {
      this.loadArticles();
    }
  }

  loadArticles() {
    if (!this.isLoadingNew) {
      this.isLoadingNew = true;
      this.dataService.getLatestArticles(10, this.cursor).subscribe(response => {
        this.articles.push(...response.articles);
        Array.from(new Set(response.articles.map(obj => obj.company))).forEach(company => {
          // @ts-ignore
          if (!this.companies[company]) {
            this.dataService.getCompany(company).subscribe(cRe => {
              this.companies[cRe.id] = cRe;
            });
          }
        });
        this.cursor = response.cursor;
        this.isLoadingNew = false;
      });
    }
  }

  getCompanyName(id) {
    if (this.companies && this.companies[id]) {
      return this.companies[id].name;
    }
    return '-';
  }

  articleLink(id, title) {
    return id + '-' + title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  }
}
