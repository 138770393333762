import {Component, Input, OnInit} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {EnvService} from '../../../services/env.service';
@Component({
  selector: 'app-frames-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  public fa = icons;
  @Input() title;
  @Input() date;
  @Input() id;
  @Input() companyName;
  @Input() version;
  public mainImage;
  public loading;

  constructor(private envService: EnvService) {
  }

  ngOnInit(): void {
    this.loading = false;
    this.mainImage = `${this.envService.imageUrl}/articles/${this.id}/images/${this.version + '.main'}`;
  }
}
