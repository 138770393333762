<div class="wrapper" (click)="loading = true">
  <div class="frame">
    <div class="set" [ngClass]="{'loading': loading}" [ngStyle]="{'background-image': 'url(' + mainImage + ')'}">
      <fa-icon [icon]="fa.faSyncAlt" *ngIf="loading"></fa-icon>
    </div>
    <div class="info">
      <p>{{companyName || ''}}<fa-icon [icon]="fa.faCheckCircle" *ngIf="companyName"></fa-icon></p>
      <p [title]="date | utcToClientTimezone">{{date | customDateTime}}</p>
      <h5>{{title}}</h5>
    </div>
  </div>
</div>
