import {Component, HostListener} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {AlertService} from '../../../../services/alert.service';

@Component({
  selector: 'app-landing-feed',
  templateUrl: './landing-feed.component.html',
  styleUrls: ['./landing-feed.component.scss']
})
export class LandingFeedComponent {
  public fa = icons;
  public results = [];
  public cursors = {company: null, topic: null, statement: null};
  public isLoadingNew = false;
  public companies = {};
  public retrieved = [];
  public extraResults = [];

  constructor(private dataService: DataService, private alertService: AlertService) {
    this.loadResults();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && this.canGetMore()) {
      this.loadResults();
    }
  }


  loadResults() {
    if (!this.isLoadingNew) {
      this.isLoadingNew = true;
      const limit = 20 - this.extraResults.length;
      if (this.extraResults.length === 20) {
        this.results.push(...this.extraResults);
        this.extraResults = [];
        this.isLoadingNew = false;
        return;
      }
      this.dataService.getSubscriptionLatestArticles(JSON.stringify(this.cursors), this.retrieved, limit).subscribe(response => {
        if (this.extraResults.length > 0) {
          this.results.push(...this.extraResults);
          this.extraResults = [];
        }
        this.results.push(...response.results.slice(0, 20));
        this.extraResults.push(...response.results.slice(20));
        response.results.forEach(result => {
          if (!this.retrieved[result.id]) {
            this.retrieved.push(result.id);
          }
        });
        Array.from(new Set(response.results.map(obj => obj.company))).forEach(company => {
          // @ts-ignore
          if (!this.companies[company]) {
            this.dataService.getCompany(company).subscribe(cRe => {
              this.companies[cRe.id] = cRe;
            });
          }
        });
        this.cursors = response.cursors;
        this.isLoadingNew = false;
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}: Could not load articles`,
            title: 'Something went wrong',
            type: 'error'
          });
      });
    }
  }

  canGetMore() {
    if (this.cursors.company || this.cursors.topic || this.cursors.statement || this.extraResults.length > 0) {
      return true;
    }
  }

  getCompanyName(id) {
    if (this.companies && this.companies[id]) {
      return this.companies[id].name;
    }
    return '-';
  }

  articleLink(id, title) {
    return id + '-' + title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  }
}
