import { Component } from '@angular/core';

@Component({
  selector: 'app-frames-sponsored',
  templateUrl: './sponsored.component.html',
  styleUrls: ['./sponsored.component.scss']
})
export class SponsoredComponent {

}
