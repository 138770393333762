import { Injectable } from '@angular/core';
import {language} from '../../assets/languages/en.language';
import {language as nlLanguage} from '../../assets/languages/nl.language';
import {Observable} from 'rxjs';
import {HttpEvent} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private selectedLanguage: string;
  private languageObject: typeof language;

  constructor() {
    // Retrieve the language from storage or set a default value
    if (typeof localStorage === 'undefined') {
      this.selectedLanguage = 'en';
    } else {
      this.selectedLanguage = localStorage.getItem('language') || 'en';
    }

    this.language();
  }

  private language() {
    this.languageObject = this.getLanguageObject(this.selectedLanguage);
  }

  public getLanguageObject(lang: string): typeof language {
    let languageObject = language;

    if (lang === 'nl') {
      languageObject = nlLanguage;
    }

    return languageObject;
  }

  public setLanguage(lang: string): void {
    this.selectedLanguage = lang;
    localStorage.setItem('language', lang);
    this.language();
  }

  public lo(): typeof language {
    return this.languageObject;
  }
}
