import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'unixToDate'
})
export class UnixToDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: string): string {
    const timestamp = Number(value) * 1000; // Assuming value is in seconds
    const date = new Date(timestamp);
    const datePipe = new DatePipe(this.locale);
    return datePipe.transform(date, 'EEE, MMM d, yyyy');
  }
}
