import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../auth/auth.service';
import {DataService} from '../../../../services/data.service';

@Component({
  selector: 'app-prof-set-tab-organisations',
  templateUrl: './prof-set-tab-organisations.component.html',
  styleUrls: ['./prof-set-tab-organisations.component.scss']
})
export class ProfSetTabOrganisationsComponent implements OnInit {
  public loadingCycles = {};
  constructor(public authService: AuthService, private dataService: DataService) {
  }

  ngOnInit(): void {
    for (const x of this.authService.authorization) {
      this.loadingCycles[x.company] = true;
      if (!x.info) {
        this.dataService.getCompany(x.company).subscribe(response => {
          this.loadingCycles[x.company] = false;
          x.info = response;
        });
      }
    }
  }

}
