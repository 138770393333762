import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import * as rIcons from '@fortawesome/free-regular-svg-icons';
import {FaConfig} from '@fortawesome/angular-fontawesome';
import {AlertService} from '../../services/alert.service';
import {Title} from '@angular/platform-browser';
import {DataService} from '../../services/data.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {EnvService} from '../../services/env.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public fa = icons;
  public far = rIcons;

  @ViewChild('lengthContent') lengthContent;

  public saving = false;
  public title = '';
  public topic;
  public company;
  public mainImage;

  public changedMainImage = false;
  public preSelectedId;
  public preSelectedTopic;
  public preSelectedCompany;

  public blocks = [
    {
      type: 'text',
      content: ''
    }
  ];

  constructor(faConfig: FaConfig, private alertService: AlertService,
              private renderer: Renderer2, private titleService: Title,
              private dataService: DataService, private router: Router,
              private authService: AuthService, public envService: EnvService,
              private route: ActivatedRoute) {
    this.titleService.setTitle(`Edit - Wireplate`);
    faConfig.fixedWidth = true;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.preSelectedId = +params.id;
      if (this.preSelectedId) {
        this.editExisting();
      }
    });
  }

  editExisting() {
    this.dataService.getArticle(this.preSelectedId).subscribe(response => {
      this.title = response.title;
      // setTimeout(() => {
      //   const titleElem = document.getElementById('title');
      //   titleElem.style.overflow = 'hidden';
      //   titleElem.style.height = 'auto';
      //   titleElem.style.height = titleElem.scrollHeight + 'px';
      // }, 10);
      this.blocks = response.content;
      this.preSelectedTopic = response.topic;
      this.preSelectedCompany = response.company;
      this.mainImage = `${this.envService.imageUrl}/articles/${this.preSelectedId}/images/${response.version + '.main'}`;
    });
  }

  imageSource(source) {
    const base64Regex = /^data:image\/[a-zA-Z]*;base64,/;
    if (base64Regex.test(source)) {
      return source;
    }
    if (this.preSelectedId) {
      return this.envService.imageUrl + '/articles/' + this.preSelectedId + '/images/' + source;
    }
    return source;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.blocks, event.previousIndex, event.currentIndex);
  }

  addBlock(type) {
    this.blocks.push({
      type,
      content: ''
    });
  }

  removeBlock(index) {
    if (this.blocks.length > 1) {
      this.blocks.splice(index, 1);
    }
  }

  normalize(text) {
    // Replace '<div>' tags with line breaks
    text = text.replace(/<div>/g, '\n');

    // Replace '<br>' tags with a single whitespace
    text = text.replace(/<br>/g, ' ');

    // Replace '</div>' tags with an empty string
    text = text.replace(/<\/div>/g, '');

    return text.normalize('NFKC');
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text/plain');

    // Save the current selection range
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    // Insert the pasted text at the saved range
    range.deleteContents();
    const textNode = document.createTextNode(pastedText);
    range.insertNode(textNode);

    // Move the cursor to the end of the pasted text
    range.setStartAfter(textNode);
    range.collapse(true);

    // Restore the selection with the cursor at the end of the pasted text
    selection.removeAllRanges();
    selection.addRange(range);
  }

  onFileSelected(event, isBlock = false, inputElement: HTMLInputElement) {
    const file: File = event.target.files[0];
    if (!file || !file.type.startsWith('image/')) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (isBlock) {
        this.blocks.push({
          type: 'image',
          content: reader.result.toString()
        });
      } else {
        this.mainImage = reader.result.toString();
        this.changedMainImage = true;
      }
      inputElement.value = null;
    };
  }

  public save() {
    if (this.title.length > 100) {
      this.alertService.alert(
        {
          content: `Your title is too long`,
          title: 'Blocked',
          type: 'warning'
        });
      return;
    }
    let stop = false;
    this.blocks.forEach(block => {
      if (block.type === 'image') {
        // @ts-ignore
        if (block.alt && block.alt.length > 65) {
          this.alertService.alert(
            {
              content: `One of your image descriptions is too long`,
              title: 'Blocked',
              type: 'warning'
            });
          stop = true;
        }
      }
    });

    if (stop) {
      return;
    }

    if (this.preSelectedId) {
      if (!(this.title && this.blocks && this.mainImage)) {
        this.alertService.alert(
          {
            content: `Check your title, content and image selection`,
            title: 'You forgot something',
            type: 'warning'
          });
        return;
      }
      this.saving = true;

      let content;
      if (this.changedMainImage) {
        content = {title: this.title, blocks: this.blocks, mainImage: this.mainImage};
      } else {
        content = {title: this.title, blocks: this.blocks};
      }

      this.dataService.putArticle(this.preSelectedId, content).subscribe(response => {
        this.alertService.alert(
          {
            content: `The article has been updated`,
            title: 'Saved',
            type: 'info'
          });
        this.router.navigate([`article/${this.preSelectedId}`]);
        this.saving = false;
      }, error => {
        this.alertService.alert(
          {
            content: `The article could not be updated`,
            title: 'Something went wrong',
            type: 'error'
          });
        this.saving = false;
      });
      return;
    }

    if (!(this.title && this.blocks && this.topic && this.company && this.mainImage)) {
      this.alertService.alert(
        {
          content: `Check your title, content, topic, company and image selection`,
          title: 'You forgot something',
          type: 'warning'
        });
      return;
    }
    this.saving = true;

    this.dataService.postArticle(Number(this.company),
      {title: this.title, blocks: this.blocks, topic: this.topic, mainImage: this.mainImage})
      .subscribe(response => {
        this.alertService.alert(
          {
            content: `The article has been saved`,
            title: 'Saved',
            type: 'info'
          });
        this.router.navigate([`article/${response}`]);
        this.saving = false;
      }, error => {
        if (error.error === 'You have reached your article limit') {
          this.alertService.alert(
            {
              content: `You have reached the article limit for this month`,
              title: 'Limit reached',
              type: 'warning'
            });
        } else {
          this.alertService.alert(
            {
              content: `The article could not be saved`,
              title: 'Something went wrong',
              type: 'error'
            });
        }

        this.saving = false;
      });
  }
}
