<div class="container top">
  <div class="row header">
    <a class="col item" [routerLink]="['/feed']" [routerLinkActive]="['is-active']" style="text-align: center" *ngIf="hasAuth">{{ls.lo().Following}}</a>
    <a class="col item" [routerLink]="['/']" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact: true}" style="text-align: center">{{ls.lo().Front_page}}</a>
    <a class="col item" [routerLink]="['/latest']" [routerLinkActive]="['is-active']" style="text-align: center">{{ls.lo().Just_in}}</a>
  </div>
</div>
<div class="container content">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
