import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  public loginFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private titleService: Title,
              private router: Router) {
    this.titleService.setTitle(`Signup - Wireplate`);
    this.loginFormGroup = this.formBuilder.group(
      {
        email: ['', Validators.required],
        password: ['', Validators.required]
      }
    );
  }

  signup() {
    if (this.loginFormGroup.valid && this.loginFormGroup.dirty) {
      this.authService.SignUp(this.loginFormGroup.value.email, this.loginFormGroup.value.password);
    }
  }


}
