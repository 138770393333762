import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {DataService} from '../services/data.service';

@Injectable()
export class ArticleResolver implements Resolve<any> {
  constructor(private dataService: DataService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const articleId = route.paramMap.get('id').split('-')[0];
    return this.dataService.getArticle(articleId);
  }
}
