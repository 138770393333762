import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alert$: BehaviorSubject<{ content: string, title: string, type: string }> =
    new BehaviorSubject<{ content: string, title: string, type: string }>({content: '', title: '', type: ''});

  alert(message: { content: string, title: string, type: string }): void {
    this.alert$.next(message);
  }
}
