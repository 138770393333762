<div class="container">
  <div class="row wrapper">
    <div class="content">
      <div class="image"
           [ngStyle]="{ 'background-image': mainImage ? 'url(' + mainImage + ')' : 'url(assets/images/imageplaceholder.png)' }"
           (click)="fileInput.click()">
        {{ mainImage ? '' : 'Click to add image' }}
        <input type="file" #fileInput style="display: none" (change)="onFileSelected($event, false, fileInput)" accept=".png, .jpg, .jpeg">
      </div>
      <div class="radios" *ngIf="!preSelectedCompany && !preSelectedTopic">
        <app-company-selector (valueEvent)="company = $event.id"></app-company-selector>
        <app-topic-selector style="margin-left: 15px" (valueEvent)="topic = $event.id"></app-topic-selector>
      </div>
      <div class="radios" *ngIf="preSelectedCompany && preSelectedTopic">
        <app-company-selector [preSelectedCompanyId]="preSelectedCompany"></app-company-selector>
        <app-topic-selector style="margin-left: 15px" [preSelectedTopicId]="preSelectedTopic"></app-topic-selector>
      </div>
      <div class="text">
        <small [ngStyle]="{'color': title.length > 100 ? '#ff4545' : 'black'}">{{title.length}}/100 characters</small>
        <textarea class="input title" placeholder="Title here..." autoresize [wantsSingleLine]="true" style="resize: none" [(ngModel)]="title" id="title" (input)="title = normalize(title)"></textarea>
      </div>
      <div cdkDropList class="blocks" (cdkDropListDropped)="drop($event)">
        <div class="block" *ngFor="let block of blocks; index as i" cdkDrag>
          <div class="header" *ngIf="block.type === 'text'">
            <textarea placeholder="(Optional) Section header" autoresize [wantsSingleLine]="true" style="resize: none" [(ngModel)]="block.header" (input)="block.header = normalize(block.header)"></textarea>
          </div>
          <div class="input item length" *ngIf="block.type === 'text'" contenteditable="true" appPlaceholder [innerHTML]="block.content" (paste)="onPaste($event)" (blur)="block.content = normalize($event.target.innerHTML)" style="white-space: pre-wrap;">
          </div>
          <div class="item block-image" *ngIf="block.type === 'image'">
            <img [src]="imageSource(block.content)" [alt]="block.alt">
            <div class="description">
              <input type="text" placeholder="Image description" [(ngModel)]="block.alt">
              <br>
              <small [ngStyle]="{'color': block.alt.length > 65 ? '#ff4545' : 'black'}" *ngIf="block.alt">{{block.alt.length}}/65 characters</small>
              <small *ngIf="!block.alt">0/65 characters</small>
            </div>
          </div>
          <div class="control">
            <fa-icon [icon]="fa.faMinusSquare" class="remove" (click)="removeBlock(i)"></fa-icon>
            <span class="move" cdkDragHandle>
              <fa-icon [icon]="fa.faSort"></fa-icon>
            </span>
          </div>
        </div>
      </div>
      <div class="add">
        <fa-icon [icon]="fa.faPenSquare" title="Text block" (click)="addBlock('text')"></fa-icon>
        <fa-icon [icon]="fa.faImage" title="Image block" (click)="imageBlockInput.click()"></fa-icon>
        <input type="file" #imageBlockInput style="display: none" (change)="onFileSelected($event, true, imageBlockInput)"
               accept=".png, .jpg, .jpeg">
      </div>
      <div class="save">
        <button class="saver" (click)="save()">
          <span *ngIf="!saving">Save</span>
          <fa-icon *ngIf="saving" [icon]="fa.faSyncAlt"></fa-icon>
        </button>
        <!--        <button class="publisher" (click)="publish()">Publish</button>-->
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
