import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-article-analytics',
  templateUrl: './article-analytics.component.html',
  styleUrls: ['./article-analytics.component.scss']
})
export class ArticleAnalyticsComponent implements OnInit {
  public exposureHistory:any[] = [
    {
      name: 'Readers',
      series: []
    },
  ];
  public exposureHistoryCumulative:any[] = [
    {
      name: 'Cumulative Readers',
      series: []
    },
  ];
  public articleId;
  public articleInfo;
  public company;
  public topic;
  public rawData;
  public lifeTime = {unique: null, total: null};

  constructor(private dataService: DataService, private authService: AuthService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.authService.changeReadyAuth.subscribe(auth => {
      if (auth) {
        this.route.params.subscribe(params => {
          this.articleId = +params.id;
          this.dataService.getArticleAnalytics(this.articleId).subscribe(response => {
            this.rawData = response;
            this.rawData.reads.forEach(obj => {
              obj.name = new Date(obj.name);
            });
            this.rawData.cumulative.forEach(obj => {
              obj.name = new Date(obj.name);
            });
            this.lifeTime.total = this.rawData.lifetime;
            this.lifeTime.unique = this.rawData.unique;
            console.log('Data printed');
            this.exposureHistory[0].series = this.rawData.reads;
            this.exposureHistoryCumulative[0].series = this.rawData.cumulative;
          });

          this.dataService.getArticleInfoFromAnalytics(this.articleId).subscribe(response => {
            this.articleInfo = response;
            this.dataService.getCompany(this.articleInfo.company).subscribe(r1 => {
              this.company = r1;
            });
            this.dataService.getTopic(this.articleInfo.topic).subscribe(r1 => {
              this.topic = r1;
            });
          });
        });
      }
    });
  }
}
