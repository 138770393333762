<div class="container">
  <div class="row wrapper">
    <div class="content">
      <img class="image" *ngIf="mainImage" [src]="mainImage" [alt]="article.title">
      <div class="skel-loading image" *ngIf="!mainImage"></div>
      <div class="deck" *ngIf="canEdit">
        <p><span>Article Options</span> <small style="float: right">Only you can see this</small></p>
        <a [routerLink]="[editRef()]" class="control" style="margin-left: 0">Edit article</a>
        <a [routerLink]="[analyticsRef()]" class="control">Analytics</a>
        <span class="control" style="float: right; color: #ff4545; font-weight: bold" *ngIf="!isDeleting" (click)="isDeleting = true"><span>Delete</span></span>
        <span class="control" style="float: right; text-decoration: none; font-weight: bold;" *ngIf="isDeleting">
          <span style="color: #ff4545; margin-right: 10px; text-decoration: underline #ff4545;" (click)="deleteArticle()">Yes, delete</span>
          <span style="text-decoration: underline;" (click)="isDeleting = false">No, keep</span>
        </span>
      </div>
      <div class="radios" *ngIf="article">
        <app-company-selector [preSelectedCompanyId]="article.company"></app-company-selector>
        <app-topic-selector [preSelectedTopicId]="article.topic"></app-topic-selector>
        <div class="date" [title]="article.created | utcToClientTimezone">{{article.created | customDateTime}}</div>
      </div>
      <div class="text" *ngIf="!article">
        <h1 class="skel-loading title"></h1>
        <div class="skel-loading content"></div>
      </div>
      <div class="text" *ngIf="article">
        <h1>{{this.article.title}}</h1>
      </div>
      <div class="blocks">
        <div class="block" *ngFor="let block of article.content">
          <div class="header" *ngIf="block.type === 'text'">
            <div>{{block.header}}</div>
          </div>
          <div class="item" *ngIf="block.type === 'text'">{{block.content}}</div>
          <div class="item block-image" *ngIf="block.type === 'image'">
            <img [src]="envService.imageUrl + '/articles/' + article.id + '/images/' + block.content" *ngIf="block.type === 'image'" [alt]="block.alt">
            <div class="description">
              {{block.alt}}
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
