import { Component } from '@angular/core';
import {AuthService} from '../../../../auth/auth.service';
import {DataService} from '../../../../services/data.service';
import {AlertService} from '../../../../services/alert.service';

@Component({
  selector: 'app-prof-set-tab-history',
  templateUrl: './prof-set-tab-history.component.html',
  styleUrls: ['./prof-set-tab-history.component.scss']
})
export class ProfSetTabHistoryComponent {

  public history;

  constructor(private authService: AuthService, private dataService: DataService, private alertService: AlertService) {
    this.authService.changeReady.subscribe(message => {
      if (message) {
        this.dataService.getPersonalHistory().subscribe(response => {
          this.history = response;
        }, error => {
          this.alertService.alert(
            {
              content: `${error.error.detail || error.error}: Could not load history`,
              title: 'Something went wrong',
              type: 'error'
            });
        });
      }
    });
  }

  articleLink(id, title) {
    return id + '-' + title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  }

}
