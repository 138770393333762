<div class="container">
  <div class="row">
    <ng-container *ngFor="let result of results">
      <div class="item col col-md-3">
        <a [routerLink]="['/article', articleLink(result.id, result.title)]" class="d-block text-decoration-none text-dark">
          <app-frames-image [title]="result.title" [date]="result.created" [id]="result.id" [version]="result.version" [companyName]="getCompanyName(result.company)"></app-frames-image>
        </a>
      </div>
    </ng-container>
  </div>
</div>
<div class="end" *ngIf="isLoadingNew && !results">
  <h1><fa-icon [icon]="fa.faSyncAlt"></fa-icon></h1>
</div>
