<div class="wrapper">
  <h2>Organisations</h2>
  <p>These are the organisations that you have access to.</p>
  <div class="open">
    <a [routerLink]="['/org/new']">
      <button class="display">
        Open new organisation
      </button>
    </a>
  </div>
  <div class="organisations">
    <div class="row organisation" *ngIf="!authService.authorization">
      No organisations found that link to your account.
    </div>
    <a *ngFor="let org of authService.authorization" [routerLink]="['/org/' + org.company]">
      <div class="row organisation">
        <div class="col-10 info">
          <div *ngIf="org.info">{{org.info.name}}</div>
          <div *ngIf="loadingCycles[org.company] && !org.info">Loading information...</div>
          <div class="under">{{org.company}}</div>
          <div class="under wrong" *ngIf="!org.info && !loadingCycles[org.company]">We could not find any information associated with this
            organisation. Please contact the administrator for this organisation.
          </div>
        </div>
        <div class="col-2">
          {{org.role}}
        </div>
      </div>
    </a>
  </div>
</div>
