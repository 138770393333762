import {Component, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {DomSanitizer, Title, Meta} from '@angular/platform-browser';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EnvService} from '../../services/env.service';
import {AuthService} from '../../auth/auth.service';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  public article;
  public mainImage;
  public canEdit = false;
  public isDeleting = false;
  @ViewChild('lengthContent') lengthContent;

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private titleService: Title, private dataService: DataService, private renderer: Renderer2, public envService: EnvService,
              private route: ActivatedRoute, private authService: AuthService, private alertService: AlertService,
              private router: Router, private meta: Meta) {
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.article = data.article;
      this.mainImage = `${this.envService.imageUrl}/articles/${this.article.id}/images/${this.article.version + '.main'}`;
      this.meta.updateTag({property: 'og:title', content: data.article.title});
      this.meta.updateTag({property: 'og:image', content: this.mainImage});

      this.meta.updateTag({property: 'twitter:title', content: data.article.title});
      this.meta.updateTag({property: 'twitter:image', content: this.mainImage});

      this.titleService.setTitle(`${this.article.title} - Wireplate`);

      this.authService.changeReadyAuth.subscribe(auth => {
        this.canEdit = auth && auth.filter(e => e.company.toString() === data.article.company.toString()).length > 0;
      });
    });
  }

  deleteArticle() {
    if (this.isDeleting && window.confirm('Are you sure you want to delete this article? You cannot undo this action.')) {
      this.dataService.deleteArticle(this.article.id).subscribe(response => {
        this.alertService.alert(
          {
            content: `Deleted article`,
            title: 'Deleted',
            type: 'success'
          });
        this.router.navigate(['']);
      }, error => {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}`,
            title: 'Could not delete article',
            type: 'error'
          });
      });
    } else {
      this.isDeleting = false;
    }
  }

  editRef() {
    return `/edit/${this.article.id}`;
  }

  analyticsRef() {
    return `/article/${this.article.id}/analytics`;
  }

}
