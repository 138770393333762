export const language = {
  Following: 'Following',
  Follow: 'Follow',
  For_you: 'For you',
  Front_page: 'Front page',
  Just_in: 'Just in',
  Log_in: 'Log in',
  Sign_up: 'Sign up',
  Sign_out: 'Sign out',
  Create: 'Create',
  Article: 'Article',
  Just_now: 'Just now',
  minutes_ago: 'minutes ago',
  hour_ago: 'hour ago',
  hours_ago: 'hours ago',
  Policies: 'Policies',
  Privacy_policy: 'Privacy policy',
  Terms_of_use: 'Terms of use',
  Cookie_policy: 'Cookie policy',
  Your_data: 'Your data',
  Support: 'Support',
  Help_Support: 'Help & Support',
  Contact: 'Contact',
  Information: 'Information',
  by: 'by',
  All_rights_reserved: 'All rights reserved',
  NOTICE_disclaimer: 'Wireplate is developed, operated and managed by Inphent. Content is created by users and other external parties.',
  NOTICE_language_disclaimer: '',
  Visit_website: 'Visit website',
  More: 'More',
  Admin_Settings: 'Admin & Settings',
  Latest_releases: 'Latest releases',
  Latest_statements: 'Latest statements',
  NOTICE_statements: 'Statements are short and quick messages by an organisation. You can find them here, or on your following page.',
};
