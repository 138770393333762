<div class="skel-loading selector" *ngIf="preSelectedCompanyId && !selectedCompany"></div>
<div class="selector" *ngIf="showSelector()">
  <a [routerLink]="['/org/' + preSelectedCompanyId + '-' + selectedCompany.name]" *ngIf="preSelectedCompanyId">
    <div class="display pre">
      <span [title]="selectedCompany.name">{{selectedCompany.name}}</span>
    </div>
  </a>
  <div class="display" (click)="expanded = !expanded"  *ngIf="!preSelectedCompanyId">
    <span [title]="selectedCompany ? selectedCompany.name : 'Select organisation'">{{selectedCompany ? selectedCompany.name : 'Select organisation'}}</span>
    <fa-icon [icon]="expanded ? fa.faChevronUp : fa.faChevronDown"></fa-icon>
  </div>
  <div class="options" *ngIf="expanded && !preSelectedCompanyId">
    <div class="item" *ngFor="let company of companies" [ngClass]="{'selected': selectedCompany == company}" (click)="selectCompany(company); expanded = false">
      {{company.name}}
      <small>{{company.id}}</small>
    </div>
  </div>
</div>
