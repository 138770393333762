<div class="wrapper">
  <h2>History</h2>
  <p>Here you will find your history of read articles, going back 10 entries.</p>
  <div class="alert alert-primary" role="alert">
    If you read an article that you have read before, it could show up as a duplicate history entry here.
  </div>
  <div class="history">
    <div *ngFor="let entry of history">
      <a [routerLink]="['/article', articleLink(entry.article.id, entry.article.title)]" *ngIf="entry.article.id">
        <div class="entry">
          <h5>{{entry.article.title}}</h5>
          <p>Read on <span [title]="entry.history.read | utcToClientTimezone">{{entry.history.read | dateTimeTransform}}</span></p>
        </div>
      </a>
      <div *ngIf="!entry.article.id" class="entry blocked">
        <h5>This article was deleted</h5>
        <p>Read on <span [title]="entry.history.read | utcToClientTimezone">{{entry.history.read | dateTimeTransform}}</span></p>
      </div>
    </div>
  </div>
</div>
