<div class="cards">
  <a *ngFor="let item of articles" [routerLink]="['/article', articleLink(item.id, item.title)]">
    <app-frames-card [title]="item.title" [date]="item.created" [id]="item.id"
                     [companyName]="getCompanyName(item.company)" [version]="item.version"></app-frames-card>
    <app-frames-image [title]="item.title" [date]="item.created" [id]="item.id" [version]="item.version" [companyName]="getCompanyName(item.company)"></app-frames-image>
  </a>
</div>
<div class="end" *ngIf="articles.length === 0 && !isLoadingNew">
  <h5>No articles found</h5>
</div>
<div class="end" *ngIf="isLoadingNew && articles.length === 0">
  <h1>
    <fa-icon [icon]="fa.faSyncAlt"></fa-icon>
  </h1>
</div>
<div class="end" *ngIf="(cursor || cursor === undefined) && articles.length !== 0">
  <p (click)="loadArticles()" *ngIf="!isLoadingNew">See more articles</p>
  <fa-icon [icon]="fa.faSyncAlt" *ngIf="isLoadingNew"></fa-icon>
</div>
