import { Component } from '@angular/core';

@Component({
  selector: 'app-prof-set-tab-info',
  templateUrl: './prof-set-tab-info.component.html',
  styleUrls: ['./prof-set-tab-info.component.scss']
})
export class ProfSetTabInfoComponent{
  public canSave = false;
  constructor() {
  }

  saveChanges() {
    if (this.canSave) {
      this.canSave = false;
    } else {
      this.callBlockingSave();
    }
  }

  callBlockingSave() {
    const element = document.getElementById('saver');
    element.classList.add('blocking');
    setTimeout(() => {
      element.classList.remove('blocking');
    }, 1000);
  }

}
