import {Component, Input} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-frames-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent {
  public fa = icons;
  @Input() title;
  @Input() date;
  @Input() companyName;
  @Input() companyId;
  @Input() isHeld;
  @Input() deleteStatementText;
}
