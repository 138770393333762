export const language = {
  Following: 'Volgend',
  Follow: 'Volgend',
  For_you: 'Voor jou',
  Front_page: 'Voorpagina',
  Just_in: 'Net binnen',
  Log_in: 'Inloggen',
  Sign_up: 'Aanmelden',
  Sign_out: 'Uitloggen',
  Create: 'Aanmaken',
  Article: 'Artikel',
  Just_now: 'Zojuist',
  minutes_ago: 'minuten geleden',
  hour_ago: 'uur geleden',
  hours_ago: 'uur geleden',
  Policies: 'Voorwaarden',
  Privacy_policy: 'Privacy beleid',
  Terms_of_use: 'Gebruiksvoorwaarden',
  Cookie_policy: 'Cookie beleid',
  Your_data: 'Jouw data',
  Support: 'Hulp',
  Help_Support: 'Hulp & Support',
  Contact: 'Contact',
  Information: 'Informatie',
  by: 'door',
  All_rights_reserved: 'Alle rechten voorbehouden',
  NOTICE_disclaimer: 'Wireplate wordt ontwikkeld, geëxploiteerd en beheerd door Inphent. Content wordt gemaakt door gebruikers en andere externe partijen.',
  NOTICE_language_disclaimer: 'Nog niet alle onderdelen van Wireplate zijn beschikbaar in jouw taal. We werken hard aan een oplossing.',
  Visit_website: 'Naar website',
  More: 'Meer',
  Admin_Settings: 'Beheer & Instellingen',
  Latest_releases: 'Nieuwste artikelen',
  Latest_statements: 'Nieuwste statements',
  NOTICE_statements: 'Statements zijn korte en bondige berichten van een organisatie. Je kunt ze hier vinden, of op je Volgend pagina.',
};
