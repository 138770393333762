<div class="container" *ngIf="articleInfo">
  <h1>{{articleInfo.title}}</h1>
  <p>Posted by <b *ngIf="company">{{company.name}}</b><b *ngIf="!company">{{articleInfo.company}}</b>, for the <b *ngIf="topic">{{topic.name}}</b><b *ngIf="!topic">{{articleInfo.topic}}</b> topic, on <b [title]="articleInfo.created | utcToClientTimezone">{{articleInfo.created | dateTimeTransform}}</b></p>
  <div class="alert alert-primary" role="alert">
    Only readers that are logged in will generate analytics.
  </div>
  <div class="row readers">
    <h2>Lifetime Readers</h2>
    <div class="col">
      <div class="display">
        <h1>{{lifeTime.unique || '-'}}</h1>
        <p>Unique</p>
      </div>
    </div>
    <div class="col">
      <div class="display">
        <h1>{{lifeTime.total || '-'}}</h1>
        <p>Total*</p>
      </div>
    </div>
    <small style="margin-top: 50px">*Duplicate read entries are only stored when the last duplicate entry was at least 15 minutes in the past</small>
  </div>
  <div class="row history" *ngIf="exposureHistory[0].series != []">
    <h2>Reader History</h2>
    <ngx-charts-line-chart
      class="chart"
      [scheme]="{domain: ['#f405ff']}"
      [showGridLines]="false"
      [legend]="true"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [xAxisLabel]="'Date'"
      [yAxisLabel]="'Readers'"
      [timeline]="true"
      [results]="exposureHistory">
    </ngx-charts-line-chart>
  </div>
  <div class="row history" *ngIf="exposureHistoryCumulative[0].series != []">
    <h2>Cumulative Reader History</h2>
    <ngx-charts-line-chart
      class="chart"
      [scheme]="{domain: ['#00ffa3']}"
      [showGridLines]="false"
      [legend]="true"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [xAxisLabel]="'Date'"
      [yAxisLabel]="'Cumulative Readers'"
      [timeline]="true"
      [results]="exposureHistoryCumulative">
    </ngx-charts-line-chart>
  </div>
<!--  <div class="row statistics">-->
<!--    <h2>Statistics</h2>-->
<!--    <div class="col">-->
<!--      <div class="display">-->
<!--        <h1>0</h1>-->
<!--        <p>Saved</p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col">-->
<!--      <div class="display">-->
<!--        <h1>0</h1>-->
<!--        <p>Shared*</p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <small style="margin-top: 50px">*This only accounts for sharing via Wireplate's sharing methods, and does not track instances where users copy the URL</small>-->
<!--  </div>-->
</div>
