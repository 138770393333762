import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  template: `
    <div class="container">
      <h1>Privacy Policy for Wireplate</h1>

      <p>At Wireplate, we are committed to protecting the privacy and security of your personal information. This Privacy Policy explains
        how we collect, use, disclose, and safeguard your personal data when you use our website and services. By using Wireplate, you
        consent to the data practices described in this policy.</p>

      <h3>1. Information We Collect</h3>

      <h4>1.1 Personal Information:</h4>
      <p>When you sign up for Wireplate, we may collect personal information, such as your name, email address, contact information, and
        professional details. We will only collect personal information that you voluntarily provide to us.</p>

      <h4>1.2 Usage Data:</h4>
      <p>We may automatically collect certain information when you access or use Wireplate. This may include your IP address, browser type,
        operating system, referring URLs, pages viewed, and the dates/times of your visits. We may also collect data on how you interact
        with our website and services.</p>

      <h4>1.3 Cookies and Tracking Technologies:</h4>
      <p>Wireplate may use cookies and similar tracking technologies to enhance your user experience, personalize content, and collect
        information about how you use our website. Cookies are small files stored on your device that allow us to recognize and remember
        your preferences. You can manage your cookie preferences through your browser settings.</p>

      <h3>2. Use of Information</h3>

      <h4>2.1 Provide and Improve Services:</h4>
      <p>We may use the information we collect to provide and improve Wireplate services, respond to your inquiries, and personalize your
        experience. This includes tailoring news articles and content to match your interests and target audience.</p>

      <h4>2.2 Communication:</h4>
      <p>We may use your contact information to send you administrative messages, updates, newsletters, and promotional materials related to
        Wireplate. You can opt-out of receiving marketing communications at any time.</p>

      <h4>2.3 Analytics:</h4>
      <p>We may use the information we collect to analyze trends, monitor usage patterns, and gather statistical data to improve our website
        and services.</p>

      <h4>2.4 Legal Compliance:</h4>
      <p>We may use and disclose your personal information as required by applicable laws, regulations, or legal processes. We may also
        share information to enforce our policies, protect our rights and property, and respond to emergencies or claims.</p>

      <h3>3. Information Sharing</h3>

      <h4>3.1 Third-Party Service Providers:</h4>
      <p>We may engage third-party companies or individuals to provide services on our behalf, such as hosting, analytics, and customer
        support. These service providers will have access to your personal information only to perform tasks on our behalf and are obligated
        not to disclose or use it for any other purpose.</p>

      <h4>3.2 Aggregated or Anonymized Data:</h4>
      <p>We may share aggregated or anonymized data that does not personally identify you for various purposes, such as research, marketing,
        or improving our services.</p>

      <h4>3.3 Legal Requirements:</h4>
      <p>We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g.,
        a court or government agency).</p>

      <h4>3.4 Business Transfers:</h4>
      <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, we may transfer your personal information to the
        acquiring entity or successor.</p>

      <h3>4. Data Security</h3>

      <p>We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure,
        alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot
        guarantee absolute security.</p>

      <h3>5. Your Choices</h3>

      <p>You have the right to access, correct, update, and delete your personal information. You may exercise these rights by contacting us
        using the information provided at the end of this Privacy Policy. Please note that we may retain certain information as required by
        law or for legitimate business purposes.</p>

      <h3>6. Children's Privacy</h3>

      <p>Wireplate is not intended for use by individuals under the age of 16. We do not knowingly collect personal information from
        children. If you believe that we may have inadvertently collected personal information from a child, please contact us immediately,
        and we will take appropriate steps to remove the information.</p>

      <h3>7. Changes to this Privacy Policy</h3>

      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised "Effective Date." We
        encourage you to review this Privacy Policy periodically to stay informed about our data practices.</p>

      <h3>8. Contact Us</h3>

      <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:</p>

      <a target="_blank" href="https://github.com/Inphent/wireplate-support">Wireplate Support on Github</a>
      <br>
      <br>
      <p>We will make reasonable efforts to address your inquiries and resolve any concerns.</p>
    </div>
  <app-footer></app-footer>`,
})
export class PrivacyPolicy {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Privacy policy - Wireplate');
  }


}
