<div class="wrapper">
  <div class="container">
    <h1>Wireplate Search AI</h1>
    <h3>Wireplate Search AI allows you to search Wireplate articles, not using keywords like "article", but with regular questions. Ask the AI something, like "How do organizations use Wireplate?", and it will search Wireplate for articles that could help you with finding an answer.</h3>
    <div class="row searchbox">
      <div class="search">
        <input type="text" placeholder='Search Wireplate. E.g. "How do organizations use Wireplate?"'>
      </div>
    </div>
    <div class="row resulting">
      <div class="in">
        <div class="cards">
          <a *ngFor="let item of articles" [routerLink]="['/article', articleLink(item.id, item.title)]">
            <app-frames-card [title]="item.title" [date]="item.created" [id]="item.id"
                             [companyName]="getCompanyName(item.company)" [version]="item.version"></app-frames-card>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
