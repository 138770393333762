import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {AuthService} from '../../auth/auth.service';
import * as rIcons from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {

  public user = null;
  public fa = icons;
  public far = rIcons;

  constructor(public authService: AuthService, private titleService: Title) {
    this.titleService.setTitle('Account settings - Wireplate');
  }

  ngOnInit(): void {
  }

}
