import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {AlertService} from '../../../../services/alert.service';
import {ActivatedRoute} from '@angular/router';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-org-set-tab-billing',
  templateUrl: './org-set-tab-billing.component.html',
  styleUrls: ['./org-set-tab-billing.component.scss']
})
export class OrgSetTabBillingComponent implements OnInit {
  public fa = icons;
  public id;
  public loadingSub = false;
  public loading = false;
  public subscription;
  public history;

  constructor(private dataService: DataService, private alertService: AlertService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.parent?.params.subscribe(params => {
      this.id = +params.id;
      this.getSubscription();
      this.getHistory();
    });
  }

  getSubscription() {
    this.loadingSub = true;
    this.dataService.getCompanyPaymentSubscription(this.id).subscribe(
      response => {
        this.subscription = response;
        this.loadingSub = false;
      },
      error => {
        if (error.status === 403) {
          this.alertService.alert(
            {
              content: `You are not allowed to view this information. If you think this is a mistake, please contact the administrator or owner of this organisation`,
              title: 'Forbidden',
              type: 'warning'
            });
        } else {
          this.loadingSub = false;
        }
      }
    );
  }

  getHistory() {
    this.loading = true;
    this.dataService.getCompanyPaymentHistory(this.id).subscribe(response => {
      this.history = response;
      this.loading = false;
    }, error => {
      this.loadingSub = false;
      if (error.status === 403) {
        this.alertService.alert(
          {
            content: `You are not allowed to view this information. If you think this is a mistake, please contact the administrator or owner of this organisation`,
            title: 'Forbidden',
            type: 'warning'
          });
      } else {
        this.alertService.alert(
          {
            content: `${error.error.detail || error.error}`,
            title: 'Something went wrong',
            type: 'error'
          });
      }
    });
  }

}
