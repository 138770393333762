import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  template: `
    <div class="container">
      <h1>Terms of Use</h1>

      <p>These Terms of Use ("Terms") govern your access to and use of Wireplate website and services ("Wireplate," "we," "us," or "our"). By accessing or using Wireplate, you agree to comply with these Terms. If you do not agree with these Terms, you should not use Wireplate.</p>

      <h3>1. User Eligibility</h3>

      <p>You must be at least 16 years old to use Wireplate. By using Wireplate, you represent and warrant that you are 16 years of age or older.</p>

      <h3>2. User Obligations</h3>

      <p>2.1 Compliance with Laws:</p>
      <p>You agree to comply with all applicable laws and regulations when using Wireplate.</p>

      <p>2.2 Account Information:</p>
      <p>You are responsible for providing accurate and complete information when creating your Wireplate account. You are also responsible for keeping your account credentials confidential and for all activities that occur under your account.</p>

      <p>2.3 Prohibited Activities:</p>
      <p>When using Wireplate, you agree not to engage in any of the following activities:</p>
      <ul>
        <li>Violating any laws or regulations;</li>
        <li>Violating the rights of others;</li>
        <li>Interfering with or disrupting Wireplate's services;</li>
        <li>Uploading or transmitting viruses, malware, or any other malicious code;</li>
        <li>Attempting to gain unauthorized access to Wireplate's systems or networks;</li>
        <li>Engaging in any activity that could damage, disable, or impair Wireplate's services;</li>
        <li>Using Wireplate for any unlawful, harmful, or fraudulent purposes;</li>
        <li>Impersonating any person or entity or falsely stating or misrepresenting your affiliation with a person or entity;</li>
        <li>Collecting or harvesting personally identifiable information from other users;</li>
        <li>Engaging in any activity that violates the privacy or data protection rights of others;</li>
        <li>Posting or transmitting any unauthorized or unsolicited advertising, promotional materials, or spam;</li>
        <li>Using any automated means, including bots, crawlers, or spiders, to access or interact with Wireplate;</li>
        <li>Reverse engineering, decompiling, disassembling, or otherwise attempting to derive the source code or structure of Wireplate;</li>
        <li>Assisting or encouraging any third party in engaging in any activity prohibited by these Terms.</li>
      </ul>

      <h3>3. Intellectual Property</h3>

      <p>Wireplate and its content, including but not limited to text, graphics, logos, and software, are protected by intellectual property rights. You may not modify, reproduce, distribute, or create derivative works of any content from Wireplate without prior written consent from the respective rights holders.</p>

      <h3>4. Disclaimer of Warranties</h3>

      <p>Wireplate is provided on an "as is" and "as available" basis without any warranties or representations, express or implied. We do not guarantee the accuracy, reliability, or availability of Wireplate or its content. Your use of Wireplate is at your sole risk.</p>

      <h3>5. Limitation of Liability</h3>

      <p>To the maximum extent permitted by law, Wireplate and its affiliates, officers, directors, employees, agents, and suppliers shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with your use of Wireplate.</p>

      <h3>6. Indemnification</h3>

      <p>You agree to indemnify and hold harmless Wireplate and its affiliates, officers, directors, employees, agents, and suppliers from any claims, liabilities, damages, losses, costs, or expenses arising out of or in any way related to your use of Wireplate or violation of these Terms.</p>

      <h3>7. Modifications to Terms</h3>

      <p>We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting the revised Terms on Wireplate. Your continued use of Wireplate after the posting of the revised Terms constitutes your acceptance of the changes.</p>

      <h3>8. Termination</h3>

      <p>We may terminate or suspend your access to Wireplate at any time and for any reason, without prior notice or liability.</p>

      <h3>9. Governing Law and Jurisdiction</h3>

      <p>These Terms shall be governed by and construed in accordance with the laws of the Netherlands. Any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in the Netherlands.</p>

      <h3>10. Contact Us</h3>

      <p>If you have any questions, concerns, or requests regarding these Terms, please contact us at:</p>

      <a target="_blank" href="https://github.com/Inphent/wireplate-support">Wireplate Support on Github</a>
      <br>
      <br>
      <p>We will make reasonable efforts to address your inquiries and resolve any concerns.</p>
    </div>
  <app-footer></app-footer>`,
})
export class TermsOfUse {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Terms of use - Wireplate');
  }


}
