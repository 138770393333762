<div class="wrapper">
  <div class="profset">
    <div class="information">
      <h1>Organisation</h1>
      <h5>Organisational settings</h5>
    </div>
    <div class="row">
      <div class="col-md-4 col-lg-4 col-xl-4 navigation">
        <a [routerLink]="['general']">
          <div class="navigation-item"
               [routerLinkActive]="['is-active']">
            General
          </div>
        </a>
        <a [routerLink]="['subscription']">
          <div class="navigation-item"
               [routerLinkActive]="['is-active']">
            Subscription
          </div>
        </a>
        <hr>
        <a [routerLink]="['roles']">
          <div class="navigation-item important"
               [routerLinkActive]="['is-active']">
            Roles
          </div>
        </a>
        <a href="https://github.com/Inphent/wireplate-support" target="_blank"><div class="navigation-item important"
                                                           [routerLinkActive]="['is-active']">
          Support & Help <fa-icon [icon]="fa.faExternalLinkAlt"></fa-icon>
        </div></a>
      </div>
      <div class="col-md-8 col-lg-8 col-xl-8 content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
