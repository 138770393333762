import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {LanguageService} from '../services/language.service';

@Pipe({
  name: 'customDateTime'
})
export class CustomDateTimePipe implements PipeTransform {

  constructor( @Inject( LOCALE_ID ) private locale: string, private ls: LanguageService) { }
  transform(value: string): string {
    const date = new Date(value);
    const now = new Date();
    const diffInMilliseconds = now.getTime() - date.getTime();
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInHours < 1) {
      return diffInMinutes === 0 ? this.ls.lo().Just_now : `${diffInMinutes} ${this.ls.lo().minutes_ago}`;
    } else if (diffInHours < 24) {
      if (diffInHours === 1) {
        return `${diffInHours} ${this.ls.lo().hour_ago}`;
      }
      return `${diffInHours} ${this.ls.lo().hours_ago}`;
    } else {
      const datePipe = new DatePipe(this.locale);
      return datePipe.transform(date, 'd MMMM, yyyy \'at\' HH:mm');
    }
  }
}
