<div class="container">
  <div class="row mobile-hide">
    <div class="col col-6">
      <a [routerLink]="['/']" (click)="resetScroll()" style="color: black; text-decoration: none">
        <h2 style="cursor: pointer;"><img src="assets/images/dots.png" draggable="false">Wireplate
        </h2>
      </a>
    </div>
    <div class="col col-6">
      <div class="search">
        <a [routerLink]="['/search']" style="color: black; text-decoration: none">
          <fa-icon [icon]="fa.faSearch"></fa-icon>
        </a>
      </div>
      <button type="button" class="release" [routerLink]="['/org/new']" *ngIf="!hasOrg">Open organisation</button>
      <app-editor-selector *ngIf="hasOrg"></app-editor-selector>
      <app-login style="margin-left: 25px"></app-login>
    </div>
  </div>
  <div class="row mobile-vis">
    <div class="col col-3">
      <a [routerLink]="['/']" (click)="resetScroll()" style="color: black; text-decoration: none">
        <img src="assets/images/dots.png" draggable="false">
      </a>
    </div>
    <div class="col col-9">
      <div class="search">
        <a [routerLink]="['/search']" style="color: black; text-decoration: none">
          <fa-icon [icon]="fa.faSearch"></fa-icon>
        </a>
      </div>
      <app-login></app-login>
    </div>
  </div>
</div>
