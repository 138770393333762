<div class="login" *ngIf="!authService.userData" >
  <a [routerLink]="['/auth']">
    {{ls.lo().Log_in}}
  </a>
  •
  <a [routerLink]="['/auth/signup']">
    {{ls.lo().Sign_up}}
  </a>
</div>
<div *ngIf="authService.userData">
  <a [routerLink]="['/account']" style="margin-left: 10px"><div class="item">
    <fa-icon [icon]="far.faUserCircle"></fa-icon>
    <p>Account</p>
  </div></a>
  <div class="item" style="margin-left: 10px" (click)="authService.SignOut()">
    <fa-icon class="second" [icon]="fa.faSignOut"></fa-icon>
    <p>{{ls.lo().Sign_out}}</p>
  </div>
</div>
