import {Component, HostListener} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  public fa = icons;

  public selected = 'articles';

  public results;
  public isLoadingNew = false;
  public companies = {};
  public searchString;
  constructor(private dataService: DataService) {
  }

  loadResults() {
    if (!this.isLoadingNew && this.searchString) {
      this.isLoadingNew = true;
      const keywords = this.searchString.replace(/\s+/g, ',');

      this.dataService.getSearchResults(keywords, this.selected).subscribe(response => {
        this.results = response;
        Array.from(new Set(response.map(obj => obj.company))).forEach(company => {
          // @ts-ignore
          if (company && !this.companies[company]) {
            this.dataService.getCompany(company).subscribe(cRe => {
              this.companies[cRe.id] = cRe;
            });
          }
        });

        this.isLoadingNew = false;
      });
    }
  }

  getCompanyName(id) {
    if (this.companies && this.companies[id]) {
      return this.companies[id].name;
    }
    return '-';
  }

  articleLink(id, title) {
    return id + '-' + title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
  }
}
